import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStatsProvider} from "data/providers/api/user_stats.provider";
import {IContestsUserStat} from "data/types/user_stats";
import {Language} from "data/enums";

type IITerribleType = IContestsUserStat;

export interface IUserStatsStore {
	get list(): IITerribleType[];

	getByID(id: number): IITerribleType | undefined;

	fetch(lang: Language): Promise<IITerribleType[]>;
}

@injectable()
export class UserStatsStore implements IUserStatsStore {
	constructor(
		@inject(Bindings.UserStatsProvider) private _userStatsProvider: IUserStatsProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _list: IITerribleType[] = [];
	@observable private _init: boolean = false;

	get list() {
		return this._list;
	}

	set list(list: IITerribleType[]) {
		this._list = list;
	}

	getByID(id: number): IITerribleType | undefined {
		if (!id) {
			return;
		}
		return this._list.find((it) => {
			return it.contestId === id;
		});
	}

	async fetch(lang: Language): Promise<IITerribleType[]> {
		if (this._init) {
			return this._list;
		}
		try {
			this._init = true;
			const response = await this._userStatsProvider.get(lang);

			if (response.data.success.contestsStats) {
				runInAction(() => {
					this._init = false;
					this._list = response.data.success.contestsStats;
				});
			}
			return response.data.success.contestsStats;
		} catch (err) {
			console.log("error fetching user stats");
			this._init = false;
			return this._list;
		}
	}
}
