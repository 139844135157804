import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import type {IAnswer} from "data/types/contests";
import {Bindings} from "data/constants/bindings";
import type {
	IAnswersApiProvider,
	IAnswersPayload,
	IAnswerRequestPayload,
	IRequestRequestPayload,
	IAnswersResponse,
	IResultResponseResults,
} from "data/providers/api/answers.api.provider";
import {Language, ModalType, QuestionStatusEnum} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modal.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError, AxiosResponse} from "axios";
import {IApiResponse} from "data/services/http";
import {get} from "lodash";

type IITerribleType = IAnswer;

type IRaceResultInContest = {questionId: number; secondPlace: number[]; thirdPlace: number[]}[];

export interface IAnswersStore {
	get list(): IITerribleType[];

	set list(value: IITerribleType[]);

	get result(): IResultResponseResults;

	get hasChanges(): boolean;

	getByID(questionId: number): IITerribleType | undefined;

	fetch(params: IAnswerRequestPayload): Promise<IITerribleType[]>;

	fetchResult(params: IRequestRequestPayload): Promise<void>;

	save(params: Omit<IAnswersPayload, "answers">): Promise<IITerribleType[]>;

	clear(): void;

	add(questionId: number, value: number | string, contestId: number): void;

	has(questionId: number, value: number): boolean;

	remove(questionId: number): void;

	onAnswer(callback: () => void): void;

	offAnswer(callback: () => void): void;
}

@injectable()
export class AnswersStore implements IAnswersStore {
	private _answerCallback: Array<() => void> = [];

	constructor(
		@inject(Bindings.AnswersApiProvider) private _answersProvider: IAnswersApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestStore) public _contestsStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	@observable private _list: IITerribleType[] = [];
	@observable private _result!: IResultResponseResults;
	@observable private _errorMsg: string | null = null;

	get list() {
		return this._list;
	}

	set list(list: IITerribleType[]) {
		this._list = list;
	}

	get result() {
		return this._result;
	}

	@observable private _hasChanges: boolean = false;

	get hasChanges() {
		return this._hasChanges;
	}

	getByID(id: number): IITerribleType | undefined {
		if (!id) {
			return;
		}
		return this._list.find((it) => it.questionId === id);
	}

	clear() {
		this._list = [];
		this._hasChanges = false;
	}

	async add(questionId: number, value: number, contestId: number) {
		if (this.list.length === 0) {
			this.list = [
				{
					questionId,
					value,
				},
			];
		} else {
			this.list = [
				...this.list.filter((answer) => answer.questionId !== questionId),
				{
					questionId,
					value,
				},
			];
		}

		this._hasChanges = true;

		// this._answerCallback.forEach((callback) => callback());
		await this.save({contestId, lang: Language.EN}).catch((error: AxiosError<IApiResponse>) =>
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			})
		);
	}

	remove(questionId: number) {
		this.list = this.list.filter((answer) => answer.questionId !== questionId);
		this._hasChanges = true;
	}

	has(questionId: number, value: number) {
		return !!this._list.find(
			(answer) => answer.questionId === questionId && answer.value === value
		);
	}

	getSecondAndThirdInContest = async (
		questionIdInContest: number[],
		lang: Language,
		raceResultInContest: IRaceResultInContest
	) => {
		for (const questionId of questionIdInContest) {
			const res = await this._answersProvider.getResult({lang, questionId});
			const transformedObject: {secondPlace: number[]; thirdPlace: number[]} = {
				secondPlace: [],
				thirdPlace: [],
			};

			for (const [key, value] of Object.entries(res.data.success)) {
				if (key === "second") {
					transformedObject["secondPlace"] = value as number[];
				} else if (key === "third") {
					transformedObject["thirdPlace"] = value as number[];
				}
			}

			raceResultInContest.push({...transformedObject, questionId});
		}
	};

	async fetch(params: IAnswerRequestPayload): Promise<IITerribleType[]> {
		const response = await this._answersProvider.get(params);
		// const contest = this._contestsStore.getByID(params.contestId);
		// if (contest?.competition === "HR" || contest?.competition === "GH") {
		//if the contest is hr or gh, add second/third winner to the answer
		const allresult = (await this._answersProvider.getAllResult(params)).data.success
			.raceAnswers;
		if (response.data.success.answers) {
			runInAction(() => {
				const resultArrayWithoutSecOrThird = response.data.success.answers;

				const mergedArray = [];

				for (const obj1 of resultArrayWithoutSecOrThird) {
					const mergedObj = {...obj1};
					const matchingObj = allresult.find(
						(obj2) => obj2.questionId === obj1.questionId
					);
					if (matchingObj) {
						mergedObj.secondPlace = matchingObj?.secondPlace;
						mergedObj.thirdPlace = matchingObj?.thirdPlace;
					}
					mergedArray.push(mergedObj);
					this._list = mergedArray;
				}
			});
		}
		// }

		// if (response.data.success.answers) {

		// 	runInAction(() => {
		// 		this._list = response.data.success.answers;
		// 	});
		// }

		return response.data.success.answers;
	}

	async fetchResult(params: IRequestRequestPayload): Promise<void> {
		await this._answersProvider
			.getResult(params)
			.then((res) => {
				this._result = res.data.success.results;
			})

			.catch((error: AxiosError<IApiResponse>) => {
				const emptyResult: IResultResponseResults = {
					info: undefined,
					winner: [],
					second: [],
					third: [],
					fourth: [],
				};
				this._result = emptyResult;
			});
	}

	async save(params: Omit<IAnswersPayload, "answers">): Promise<IITerribleType[]> {
		/**
		 * F2P1-34856
		 * check if contest is live
		 * if so only send open questions
		 * so we don't get BE error on any locked Q
		 */
		const raceQuestionId = params?.raceQuestionId;
		const liveContestIDs = this._contestsStore.liveContestIDs.filter(
			(item) => item === params.contestId
		);
		const openQuestions = liveContestIDs.length
			? this._list.filter(
					(item) =>
						get(this._contestsStore.questionStatusObj, item.questionId) ===
						QuestionStatusEnum.OPEN
			  )
			: this._list;

		let response: AxiosResponse<IApiResponse<IAnswersResponse, {message: string}>>;
		raceQuestionId && delete params.raceQuestionId;
		if (raceQuestionId) {
			response = await this._answersProvider.save({
				...params,
				answers: openQuestions
					.filter((answer) => answer.questionId === raceQuestionId)
					.map((answer) => ({
						questionId: answer.questionId,
						value: answer.value,
					})),
			});
		} else {
			response = await this._answersProvider.save({
				...params,
				answers: openQuestions.map((answer) => ({
					questionId: answer.questionId,
					value: answer.value,
				})),
			});
		}
		if (response.data.success.answers) {
			runInAction(() => {
				// this._list = response.data.success.answers;
				this._hasChanges = false;
			});
		}
		return response.data.success.answers;
	}

	onAnswer(callback: () => void) {
		this._answerCallback.push(callback);
	}

	offAnswer(callback: () => void) {
		this._answerCallback = this._answerCallback.filter((fn) => fn !== callback);
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._modalsStore.showModal(ModalType.ERROR, {
			message: this._errorMsg,
			payload: "",
		});
	};
}

// async fetch(params: IAnswerRequestPayload): Promise<IITerribleType[]> {
// 	const response = await this._answersProvider.get(params);
// 	const contest = this._contestsStore.getByID(params.contestId);
// 	const raceResultInContest: IRaceResultInContest = [];
// 	if (contest?.competition === "HR" || contest?.competition === "GH") {
// 		//if the contest is hr or gh, add second/third winner to the answer
// 		const questionIdInContest = contest.questions.map((question) => question.id);
// 		await this.getSecondAndThirdInContest(questionIdInContest, lang, raceResultInContest);
// 		if (response.data.success.answers) {
// 			runInAction(() => {
// 				const resultArrayWithoutSecOrThird = response.data.success.answers;

// 				const mergedArray = [];

// 				for (const obj1 of resultArrayWithoutSecOrThird) {
// 					const mergedObj = {...obj1};
// 					const matchingObj = raceResultInContest.find(
// 						(obj2) => obj2.questionId === obj1.questionId
// 					);
// 					if (matchingObj) {
// 						mergedObj.secondPlace = matchingObj.secondPlace;
// 						mergedObj.thirdPlace = matchingObj.thirdPlace;
// 					}
// 					mergedArray.push(mergedObj);

// 					this._list = mergedArray;
// 				}
// 			});
// 		}
// 	}

// 	if (response.data.success.answers) {
// 		runInAction(() => {
// 			this._list = response.data.success.answers;
// 		});
// 	}

// 	return response.data.success.answers;
// }
