import {inject, injectable} from "inversify";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";
import {IDashboard} from "data/types/dashboard";

export interface IDashboardRequestPayload {
	lang: Language;
	competition?: string;
}

export interface IDashboardResponse {
	answers: IDashboard;
}

export interface IDashboardApiProvider {
	get: ({
		lang,
		competition,
	}: IDashboardRequestPayload) => Promise<AxiosResponse<IApiResponse<IDashboard>>>;
}

@injectable()
export class DashboardApiProvider implements IDashboardApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	get = ({lang}: IDashboardRequestPayload) => {
		return this._http.get<IApiResponse<IDashboard>>(`${lang}/predictor/dashboard/stats`);
	};
}
