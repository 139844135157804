import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IDashboardApiProvider,
	IDashboardRequestPayload,
} from "data/providers/api/dashboard.api.provider";
import type {IDashboard} from "data/types/dashboard";

export interface IDashboardStore {
	get stats(): IDashboard;

	fetch({lang, competition}: IDashboardRequestPayload): Promise<IDashboard>;

	clear(): void;
}

const defaultDashboard = {
	userId: null,
	currentWeekStats: {
		weekNumber: null,
		points: null,
		enteredContestsCount: null,
		totalContestsCount: null,
	},
	previousWeekStats: {
		weekNumber: null,
		points: null,
		enteredContestsCount: null,
		totalContestsCount: null,
	},
};

@injectable()
export class DashboardStore implements IDashboardStore {
	constructor(
		@inject(Bindings.DashboardApiProvider) private _dashboardProvider: IDashboardApiProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _stats: IDashboard = defaultDashboard;

	get stats() {
		return this._stats;
	}

	set stats(list: IDashboard) {
		this._stats = list;
	}

	clear() {
		this._stats = defaultDashboard;
	}

	async fetch({lang}: IDashboardRequestPayload): Promise<IDashboard> {
		try {
			const response = await this._dashboardProvider.get({lang});

			if (response.data.success) {
				runInAction(() => {
					this._stats = response.data.success;
				});
			}

			return response.data.success;
		} catch (err) {
			return this._stats;
		}
	}
}
