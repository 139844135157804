import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ContestStatusEnum, RequestState, WeekStatus} from "data/enums";
import {IContest} from "data/types/contests";
import type {IContestStore, IITerribleType} from "data/stores/contest/contest.store";
import {ILeaderboardItem} from "data/types/leaderboard";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import type {
	INotificationBar,
	INotificationBarStore,
} from "data/stores/notification/notification.store";
import type {IContestLeaderboardStore} from "data/stores/leaderboard/contestleaderboard.store";
import {cloneDeep, find, isEmpty} from "lodash";
import {competitionValuesToFetch, DEFAULT_LIST_SIZE} from "data/constants";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";

interface IParams {
	contestId: number;
}

export enum TableStats {
	PICKS = "picks",
	TIE_BREAKER = "tie_breaker",
	TOTAL_POINTS = "total_points",
	Last_Contest_Points = "last_contest_points",
}

export enum TableSport {
	HORSE_RACING = "HR",
	GREYHOUND_RACEING = "GH",
	AFL = "AFL",
	NRL = "NRL",
	SOO = "SOO",
	NBA = "NBA",
}

interface IParams {
	isContestLeaderboard: boolean;
	contestId: number;
	game?: string;
	isGlobalLeaderboard?: boolean;
	leaderboardContestId?: number;
}

/**
 * Used for
 * views/components/leaderboard/contest_leaderboard/contest_leaderboard.controller.ts
 * views/components/leaderboard/overall_leaderboard/overall_leaderboard.controller.ts
 */
export interface ICommonLeaderboardController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	onLoadMore: () => Promise<void>;

	get isLoading(): boolean;

	get userId(): number;

	get hasMore(): boolean;

	get list(): ILeaderboardItem[];

	get listWithCurentUser(): ILeaderboardItem[];
}

export interface ILeaderboardController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	setStats: (value: TableStats) => void;
	setSport: (value: string) => void;
	setWeek: (value: number) => void;
	setContestId: (contestId: number) => void;
	getCompletedOrLiveContestsByGame: (competitionName: string) => IITerribleType[];

	get showCompetitionCopy(): boolean;

	get selectedContestId(): number;

	get selectedStat(): TableStats;

	get selectedSport(): string;

	get selectedWeek(): number;

	get completedOrLiveContests(): IContest[];

	get isContestboard(): boolean;

	get weekList(): number[];

	get leaderboardContestId(): number | undefined;

	get leaderboardCompetitionId(): number;

	get notificationBar(): INotificationBar;

	get selectedContestLeaderBoard(): IContest | undefined;

	setIsContestLeaderboard: (isContestLeaderboard: boolean) => void;

	setWeekList: (allContestList: IContest[]) => void;

	setShowCompetitionCopy: () => void;

	onLoadMore: () => Promise<void>;

	get isLoading(): boolean;

	get userId(): number;

	get hasMore(): boolean;

	get constestList(): ILeaderboardItem[];
	get getWeekStatus(): WeekStatus;
}

@injectable()
export class LeaderboardController implements ILeaderboardController {
	@observable private _contestId: number = 0;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _weekList: number[] = [];
	@observable private _leaderboardContestId: number | undefined;
	@observable private _leaderboardCompetitionId = 0;

	private _currentPage = 1;
	private _limit: number = DEFAULT_LIST_SIZE;
	private _isContestLeaderboard: boolean = true;
	private _allContest: IContest[] = [];
	private _showCompetitionCopy: boolean = false;
	private _weekStatus: WeekStatus = WeekStatus.MISSING;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) public _contestStore: IContestStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore,
		@inject(Bindings.NotificationBarStore) private _notificationBarStore: INotificationBarStore,
		@inject(Bindings.CompetitionsStore) private _competitionStore: ICompetitionsStore,
		@inject(Bindings.ContestLeaderboardStore)
		public _contestleaderboardStore: IContestLeaderboardStore
	) {
		makeAutoObservable(this);
	}

	@observable private _selectedStat: TableStats = TableStats.TOTAL_POINTS;
	@observable private _selectedSport: string = "HR";
	@observable private _selectedWeek!: number;

	get getWeekStatus(): WeekStatus {
		return this._weekStatus;
	}
	get isLoading(): boolean {
		return this._requestState === RequestState.PENDING;
	}

	get userId(): number {
		return this._contestleaderboardStore.contestleaderboard.userRanking?.userId || 0;
	}

	get hasMore(): boolean {
		return this._contestleaderboardStore.contestleaderboard.nextPage;
	}

	get constestList(): ILeaderboardItem[] {
		const listWithoutCurrentUser = cloneDeep(
			this._contestleaderboardStore.contestleaderboard.rankings
		);
		const currentUser = this._contestleaderboardStore.contestleaderboard.userRanking;

		const isIncludingUser = listWithoutCurrentUser.some(function (item) {
			return item.userId === currentUser?.userId;
		});

		if (!isIncludingUser && currentUser) {
			listWithoutCurrentUser.unshift(currentUser);
		}

		return listWithoutCurrentUser;
	}

	onLoadMore = async () => {
		this._requestState = RequestState.PENDING;
		this._currentPage = this._currentPage + 1;

		try {
			if (this._isContestLeaderboard) {
				await this._contestleaderboardStore.fetchContestLoadMore({
					page: this._currentPage,
					lang: this.i18n.lang,
					contestId: this._leaderboardContestId,
					limit: this._limit,
				});
			} else {
				await this._contestleaderboardStore.fetchOverallLoadMore({
					page: this._currentPage,
					lang: this.i18n.lang,
					competitionId: this._leaderboardCompetitionId,
					limit: this._limit,
				});
			}

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	};

	get notificationBar() {
		return this._notificationBarStore.notificationBar;
	}

	get showCompetitionCopy() {
		return this._showCompetitionCopy;
	}

	get selectedStat() {
		return this._selectedStat;
	}

	get selectedWeek() {
		return this._selectedWeek || this.weekList[0];
	}

	get selectedSport() {
		return this._selectedSport;
	}

	get selectedContestId(): number {
		return this._contestId;
	}

	get selectedContestLeaderBoard() {
		return find(this._allContest, (contest) => contest.id === this.leaderboardContestId);
	}

	get completedOrLiveContests() {
		return this._contestStore.completedOrLiveContests;
	}

	setIsContestLeaderboard = (isContestLeaderboard: boolean) => {
		this._isContestLeaderboard = isContestLeaderboard;
	};

	get isContestboard() {
		return this._isContestLeaderboard;
	}

	get weekList() {
		return this._weekList.slice().sort((a, b) => a - b);
	}

	get leaderboardContestId() {
		return this._leaderboardContestId;
	}

	get leaderboardCompetitionId() {
		return this._leaderboardCompetitionId;
	}

	get selectedContest() {
		return this._allContest.filter((contest) => contest.competition === this._selectedSport);
	}
	setSelectedCompetition() {
		console.log(cloneDeep(this._competitionStore.list), this._selectedSport);
		this._leaderboardCompetitionId =
			find(
				this._competitionStore.list,
				(competition) =>
					competition.name.toLocaleUpperCase() === this._selectedSport.toLocaleUpperCase()
			)?.id || 0;
		// this._leaderboardCompetitionId = this.selectedContest
		// 	? this.selectedContest[0]?.competitionId
		// 	: 0;
	}

	setShowCompetitionCopy = () => {
		const openContestList = this._contestStore.thisWeekContestList.filter(
			(contest) =>
				contest.status !== ContestStatusEnum.LIVE &&
				contest.status !== ContestStatusEnum.COMPLETE
		);
		const openContestForSelectedSport = openContestList.filter(
			(contest) => contest.competition === this.selectedSport
		);
		const openContestForSelectedWeek = openContestForSelectedSport.filter(
			(contest) => contest?.weekNumber === this.selectedWeek
		);
		this._showCompetitionCopy = !!openContestForSelectedWeek.length;
	};

	setWeekList = (allContestsList: IContest[]) => {
		this._allContest = allContestsList;
		if (this._allContest.length === 0) {
			this._weekList = [];
		}
		this._allContest.forEach((contest) => {
			(contest?.weekNumber || contest?.weekNumber === 0) &&
				!this._weekList.includes(contest.weekNumber) &&
				this._weekList.push(contest.weekNumber);
		});
		this._weekList.sort((a, b) => a - b);

		const selectedWeek = this.selectedContest.find(
			(contest) => contest.weekNumber === this.selectedWeek
		);
		if (selectedWeek && selectedWeek?.status === ContestStatusEnum.COMPLETE) {
			this._weekStatus = WeekStatus.PAST;
			this._leaderboardContestId = selectedWeek.id;
		} else if (!selectedWeek) {
			this._weekStatus = WeekStatus.MISSING;
		} else {
			this._weekStatus = WeekStatus.FUTURE;
		}

		this._leaderboardContestId = selectedWeek?.id;
		void this._fetchLeaderboard();
		this.setShowCompetitionCopy();
	};

	setSport = async (value: string) => {
		this._selectedSport = value;
		this._currentPage = 1;

		if (competitionValuesToFetch.includes(value)) {
			this._isContestLeaderboard = false;
			this._leaderboardContestId = 0;
			this.setSelectedCompetition();
			await this._fetchLeaderboard();
		} else {
			this._isContestLeaderboard = true;
			const filteredContest = this._allContest.filter(
				(contest) => contest.competition === this._selectedSport
			);

			const selectedWeek = filteredContest.find(
				(contest) => contest.weekNumber === this.selectedWeek
			);
			if (selectedWeek && selectedWeek?.status === ContestStatusEnum.COMPLETE) {
				this._weekStatus = WeekStatus.PAST;
				this._contestId = selectedWeek.id;
			} else if (!selectedWeek) {
				this._weekStatus = WeekStatus.MISSING;
				this._contestId = 0;
			} else {
				this._weekStatus = WeekStatus.FUTURE;
			}
			this._leaderboardContestId = selectedWeek?.id || 0;

			await this._fetchLeaderboard();
			this.setShowCompetitionCopy();
		}
	};

	setWeek = async (value: number) => {
		this._selectedWeek = value;
		const filteredContest = this._allContest.filter(
			(contest) => contest.competition === this._selectedSport
		);

		const selectedWeek = filteredContest.find(
			(contest) => contest.weekNumber === this.selectedWeek
		);

		if (selectedWeek && selectedWeek?.status === ContestStatusEnum.COMPLETE) {
			this._weekStatus = WeekStatus.PAST;
			this._contestId = selectedWeek.id;
		} else if (!selectedWeek) {
			this._weekStatus = WeekStatus.MISSING;
		} else {
			this._weekStatus = WeekStatus.FUTURE;
		}
		this._leaderboardContestId = selectedWeek?.id || 0;

		await this._fetchLeaderboard();
		//this.setShowCompetitionCopy();
	};

	getCompletedOrLiveContestsByGame = (competitionName: string) => {
		return this._contestStore.getCompletedOrLiveContestsByGame(competitionName);
	};

	setStats = (value: TableStats) => {
		this._selectedStat = value;
	};

	setContestId = (contestId: number) => {
		this._contestId = contestId;
		this._currentPage = 1;
	};

	async init(param: IParams) {
		if (isEmpty(this._competitionStore.list)) {
			await this._competitionStore.fetch();
		}
		this._isContestLeaderboard = param.isContestLeaderboard;
		await this.setSport(this._selectedSport);
		this.setWeekList(this._contestStore.list);
	}

	private async _fetchLeaderboard() {
		if (this._requestState === RequestState.PENDING || this._leaderboardCompetitionId === 0) {
			return;
		}
		this._contestleaderboardStore.clear();
		try {
			this._requestState = RequestState.PENDING;
			if (this._isContestLeaderboard) {
				await this._contestleaderboardStore.fetchContest({
					lang: this.i18n.lang,
					contestId: this._leaderboardContestId,
					limit: this._limit,
				});
			} else {
				await this._contestleaderboardStore.fetchOverallLeaderBoard({
					lang: this.i18n.lang,
					limit: this._limit,
					competitionId: this._leaderboardCompetitionId,
				});
			}
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}
}
