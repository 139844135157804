import {Checkbox} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {
	ModalGeneral,
	ModalTabContent,
	ModalWrap,
} from "views/components/modals/modal_general.component";
import {useViewController} from "data/services/locator";
import {ButtonPrimary} from "views/components/buttons/button.component";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {IModalTermsController} from "views/components/modals/modal_terms.controller";
import {noop} from "lodash";

export const ModalTerms: React.FC = observer(() => {
	const {isOpen, error, requestState, handleFormSubmit} =
		useViewController<IModalTermsController>(Bindings.ModalTermsController);

	if (!isOpen) {
		return null;
	}

	return (
		<ModalGeneral
			isVisible={isOpen}
			heading={""}
			showCancel={false}
			onClose={noop}
			showCloseIcon={false}>
			<ModalTabContent>
				<ModalWrap>
					<form onSubmit={handleFormSubmit} noValidate={false}>
						<div className="modal-title">
							ACCEPT TERMS AND <br /> CONDITIONS
						</div>
						<div className="accept">
							<Checkbox
								sx={{color: "#111", "&.Mui-checked": {color: "#111"}}}
								defaultChecked={false}
								name="agree"
								id="agree"
								required
							/>
							<label htmlFor="agree">
								By selecting this box I agree to the{" "}
								<a href={`/help`} className="tnc">
									Terms and Conditions.
								</a>
							</label>
						</div>
						<ButtonPrimary
							className="accept-butt"
							endIcon={<ArrowForwardIcon />}
							type="submit">
							{
								{
									[RequestState.IDLE]: "ACCEPT T&C'S",
									[RequestState.PENDING]: "Updating...",
									[RequestState.SUCCESS]: "Updating...",
									[RequestState.ERROR]: error?.common,
								}[requestState]
							}
						</ButtonPrimary>
					</form>
				</ModalWrap>
			</ModalTabContent>
		</ModalGeneral>
	);
});
