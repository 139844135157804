import React, {Fragment, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Typography, Stack, useMediaQuery} from "@mui/material";

import {useViewController} from "data/services/locator";
import {IGameBarController} from "views/components/game_bar/game_bar.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {ContestCountdown} from "views/components/contest_countdown/contest_countdown.component";
import {WithTheme} from "data/types/generics";
import Box from "@mui/material/Box";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";
import {Exist} from "views/components/exist/exist.component";
import {IRacingPicksController} from "views/pages/racing_extra/racing_picks.controller";
import {useDigitParams} from "data/hooks/use_digit_param.hook";
import {CommonTooltip} from "views/components/tooltip/tooltip.component";
import {SportParam} from "data/enums";

const Div = styled.div`
	width: 100%;
	background: var(--TAB-Mobile-Item);
	opacity: 1;
`;

const GameBarWrapper = styled(Box)<WithTheme>`
	border-radius: 0 0 4px 4px;
	max-width: 1284px;
	margin: 0 auto;
	padding: 26px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	min-height: 120px;
	--ballWidth: 100px;
	--vPosition: center;
	background-size: var(--ballWidth), var(--ballWidth), 270px;
	background-position: calc(var(--ballWidth) / 2 * -1) var(--vPosition),
		calc(100% + var(--ballWidth) / 2) var(--vPosition), right center;

	@media (max-width: 860px) {
		width: 100%;
		flex-flow: column;
		gap: 8px;
		text-align: center;
		--ballWidth: 0px;
		--vPosition: calc(100% - 10px);
		padding: 16px 9px;
		align-items: flex-start;
		background-position: calc(100% + 30px) top;
	}
	@media (max-width: 1180px) {
		padding: 9px 20px;
	}
`;

const TypographyStyled = styled(Typography)`
	color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
	font-size: 30px;
	font-weight: 600;
	text-transform: uppercase;
	font-family: GraphikXCondensed;
	@media (max-width: 860px) {
		font-size: 22px;
	}
`;

// const MobileOnlyDash = styled(TypographyStyled)`
// 	display: none;
// 	@media (max-width: 680px) {
// 		display: block;
// 	}
// `;

const Content = styled(Stack)<WithTheme>`
	color: ${(props) => props.theme.palette.colors.third};
	align-items: flex-start;
	text-align: left;
	overflow: hidden;

	@media (max-width: 680px) {
		flex-direction: row;
		gap: 10px;
	}

	span {
		color: ${(props) => props.theme.palette.colors.first};
	}
`;

const UserName = styled(Typography)`
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 20px;
	width: 100%;
	font-family: GraphikXCondensed;
	font-size: 30px !important;
	font-weight: 700;
	line-height: 160%; /* 32px */
	// letter-spacing: 5px;
	text-transform: uppercase;
	color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
	opacity: 1;

	@media (max-width: 680px) {
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 26.4px */
	}
`;

// UserName.defaultProps = {
// 	variant: "h3",
// 	sx: {
// 		typography: {xs: "h4", md: "h3"},
// 	},
// };

const StatsLine = styled(Stack)`
	flex-flow: row;
	margin-left: auto;
	gap: 12px;
	.clock {
		min-width: 140px;
	}
	@media (max-width: 860px) {
		margin-left: 0;
		width: 100%;
		gap: 6px;
		.clock {
			min-width: unset;
		}
	}
`;

const StatItem = styled.div`
	border-radius: 6px 0px 6px 0px;
	background-color: var(--TAB-Input-Border);
	backdrop-filter: blur(3px);
	padding: 12px 12px;
	text-align: center;
	min-width: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 90px;
	flex: 2;
	justify-content: space-between;
	@media (max-width: 860px) {
		min-width: 0;
		padding: 6px 6px;
		width: 80px;
		height: 82px;
		flex: 1;
		max-width: 100%;

		&.large {
			min-width: 0;
		}
	}
	@media (max-width: 365px) {
		justify-content: space-between;
		height: unset;

		div,
		p {
			flex: unset;
		}
	}
`;

const StatValue = styled.p`
	color: var(--TAB-Masterbrand-Secondary-TAB-Grey-80);
	font-size: 16px;
	font-weight: 600;
	font-family: var(--fontFamilyBody);
	display: flex;
	justify-self: flex-end;
	justify-content: flex-end;
	align-items: center;
	flex: 1;
	span {
		color: var(--TAB-Masterbrand-Secondary-TAB-Grey-80);
		font-size: 16px;
		font-weight: 400;
	}
	@media (max-width: 1180px) {
		font-size: 12px;
	}
	@media (max-width: 375px) {
		font-size: 11px;
	}
`;

const StatLabel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	justify-self: center;
	flex: 1;
	color: var(--TAB-Masterbrand-Secondary-TAB-Grey-80);
	font-size: 16px;
	font-weight: 400;
	font-family: var(--fontFamilyBody);
	line-height: 20px;
	span {
		color: var(--TAB-Masterbrand-Secondary-TAB-Grey-80);
		font-size: 16px;
		font-weight: 400;
		width: 80%;
	}
	@media (max-width: 1180px) {
		font-size: 12px;
		span {
			font-size: 12px;
			font-weight: 400;
			width: 80%;
		}
	}

	@media (max-width: 375px) {
		font-size: 11px;
		span {
			font-size: 11px;
			font-weight: 400;
			width: 80%;
		}
	}
`;

const StatLabelWithTooltip = styled(StatLabel)`
	flex-direction: column;
	div {
		display: flex;
		align-items: center;
		gap: 1px;
	}

	@media (max-width: 600px) {
		flex-direction: row;
	}
`;

const StatItemLarge = styled(StatItem)`
	padding: 12px 12px;
	align-items: center;
	flex: unset;
	min-width: 120px;

	.small-clock {
		@media (max-width: 860px) {
			font-size: 12px;
		}
	}
`;

export const GameBar: React.FC = observer(() => {
	const {
		dashBoardStatusLastWeekPoints,
		dashBoardStatusCurrentWeekPoints,
		displayTotalContestCount,
		displayContestEnteredCount,
		userName,
	} = useViewController<IGameBarController>(Bindings.GameBarController, {
		location: useLocation(),
	});

	return (
		<Div>
			<GameBarWrapper>
				<Content>
					<UserName>{userName}</UserName>
				</Content>
				<StatsLine>
					<StatItem className={"large"}>
						<StatLabel>Contests Entered</StatLabel>
						<StatValue>
							{displayContestEnteredCount.toLocaleString()} /{" "}
							{displayTotalContestCount.toLocaleString()}
						</StatValue>
					</StatItem>
					<StatItem>
						<StatLabel>
							<span>Last Week's Points</span>
						</StatLabel>
						<StatValue>{dashBoardStatusLastWeekPoints.toLocaleString()}</StatValue>
					</StatItem>
					<StatItem className={"large"}>
						<StatLabel>
							This Week's
							<br /> Points
						</StatLabel>
						<StatValue>{dashBoardStatusCurrentWeekPoints.toLocaleString()}</StatValue>
					</StatItem>
				</StatsLine>
			</GameBarWrapper>
		</Div>
	);
});

interface IProps {
	contestId: number;
}

interface IParams extends Record<string, string> {
	game: "afl" | "nrl" | "hr";
}

const answeredLabel = (isMobile: boolean) => {
	return isMobile ? "Answered" : "Answered / Total";
};

// const potentialLabel = (isMobile: boolean) => {
// 	return isMobile ? "Potential " : "Potential Points";
// };

const contestLabel = (isHr: boolean) => {
	return isHr ? "Raceday Points" : "Contest Points";
};

const nextMatchLabel = (isMobile: boolean) => {
	return isMobile ? "Next Match" : "Next Match Locks In";
};

interface IPotentialPointsTooltipProps {
	isMobile: boolean;
}

const PotentialPointsTooltip = ({isMobile}: IPotentialPointsTooltipProps) => {
	return (
		<StatLabelWithTooltip>
			<div>
				Potential Points
				<CommonTooltip
					content={`The aggregated number of points you can earn in this contest with your
					current picks. Potential points may update as odds fluctuate in the lead
					up to the event.`}
					size={`small`}
				/>
			</div>
		</StatLabelWithTooltip>
	);
};

export const GameBarQuestion: React.FC<IProps> = observer(({contestId}) => {
	const {game} = useParams<IParams>();
	const {id} = useDigitParams("id", "questionId", "competitionId");
	const isMobile = useMediaQuery(breakpointsTheme.down("md"));

	const controller = useViewController<IGameBarController>(Bindings.GameBarController, {
		contestId,
		location: useLocation(),
	});

	const {
		contest,
		isComplete,
		contestPoints,
		potentialPoints,
		contestRank,
		i18n,
		isAnyQuestionComplete,
		nextQuestionLockDate,
		totalNumAnswered,
		totalNumQuestions,
	} = controller;

	const isHr = game === "hr";

	let predictionCount;

	if (isHr) {
		const {userPredictionCount} = useViewController<IRacingPicksController>(
			Bindings.RacingPicksController,
			{
				navigate: useNavigate(),
				location: useLocation(),
				contestID: id,
				competition: game,
			}
		);

		predictionCount = userPredictionCount;
	}

	const picksCount = isHr ? predictionCount : totalNumAnswered;

	const contestPointsFormatted = contestPoints?.toLocaleString("en-Us") || "-";
	const contestRankFormated = contestRank?.toLocaleString("en-Us") || "-";
	const potentialPointsFormatted = potentialPoints.toLocaleString();

	const [dateVal, setDateVal] = useState<string>("");

	useEffect(() => {
		setDateVal(nextQuestionLockDate || "");
	}, [nextQuestionLockDate, setDateVal]);

	return (
		<Div>
			<GameBarWrapper>
				<Content>
					<TypographyStyled>
						{controller.getGameName(game, isMobile)} - {contest?.name}
					</TypographyStyled>
					{/* <MobileOnlyDash></MobileOnlyDash>
					<TypographyStyled>{contest?.name}</TypographyStyled> */}
				</Content>
				<StatsLine>
					<Exist when={!isComplete}>
						<Fragment>
							<StatItemLarge>
								<StatLabel>
									{i18n.t(
										"game_bar.stats.number_answered",
										`${answeredLabel(isMobile)}`
									)}
								</StatLabel>
								<StatValue>
									{picksCount} / {totalNumQuestions}
								</StatValue>
							</StatItemLarge>
							<StatItemLarge>
								<PotentialPointsTooltip isMobile={isMobile} />
								<StatValue>{potentialPointsFormatted}</StatValue>
							</StatItemLarge>
							<Exist when={isAnyQuestionComplete}>
								<StatItemLarge>
									<StatLabel>
										{i18n.t(
											"game_bar.stats.contest_points",
											`${contestLabel(isHr)}`
										)}
									</StatLabel>
									<StatValue>{contestPointsFormatted}</StatValue>
								</StatItemLarge>
							</Exist>

							<Exist when={!isHr}>
								<StatItemLarge>
									<StatLabel>
										{i18n.t(
											"game_bar.stats.lock",
											`${nextMatchLabel(isMobile)}`
										)}
									</StatLabel>
									<StatValue>
										<ContestCountdown
											key={1}
											sport={game as SportParam}
											contestId={contestId}
											lockDate={dateVal}
										/>
									</StatValue>
								</StatItemLarge>
							</Exist>
							<Exist when={isHr}>
								<StatItemLarge className="clock">
									<StatLabel>
										{i18n.t("game_bar.stats.overall_points", "Locks In")}
									</StatLabel>
									<StatValue className="small-clock">
										<ContestCountdown
											key={2}
											sport={game as SportParam}
											contestId={contestId}
											lockDate={dateVal}
										/>
									</StatValue>
								</StatItemLarge>
							</Exist>
						</Fragment>
					</Exist>

					<Exist when={isComplete}>
						<Fragment>
							<StatItemLarge>
								<PotentialPointsTooltip isMobile={isMobile} />
								<StatValue>{potentialPointsFormatted}</StatValue>
							</StatItemLarge>

							<StatItemLarge>
								<StatLabel>
									{i18n.t(
										"game_bar.stats.contest_points",
										`${contestLabel(isHr)}`
									)}
								</StatLabel>
								<StatValue>{contestPointsFormatted}</StatValue>
							</StatItemLarge>

							<StatItemLarge>
								<StatLabel>{i18n.t("game_bar.stats.rank", "Rank")}</StatLabel>
								<StatValue>{contestRankFormated}</StatValue>
							</StatItemLarge>
						</Fragment>
					</Exist>
				</StatsLine>
			</GameBarWrapper>
		</Div>
	);
});
