import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ContestStatusEnum, QuestionStatusEnum} from "data/enums";
import {find, get} from "lodash";

interface IParams {
	contestId: number;
}

export interface IContestLockStatusController extends ViewController<IParams> {
	get winner(): string;

	get contestStatus(): ContestStatusEnum;

	get nextQuestionLockDate(): string;
}

@injectable()
export class ContestLockStatusController implements IContestLockStatusController {
	@observable private _contestId = 0;

	constructor(
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestsStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get contestStatus() {
		return this.contest?.status ?? ContestStatusEnum.SCHEDULED;
	}

	get contest() {
		return this._contestsStore.getByID(this._contestId);
	}

	get winner() {
		return this.contest?.winners.join(", ") ?? "";
	}

	get nextQuestionLockDate() {
		if (this.contest) {
			const nextQuestion = find(
				this.contest.questions,
				(it) => it.status === QuestionStatusEnum.OPEN
			);
			if (nextQuestion?.lockDate) {
				return get(nextQuestion, "lockDate", "");
			} else {
				return "";
			}
		}
		return "";
	}

	init(param: IParams): void {
		this._contestId = param?.contestId ?? 0;
	}

	onChange(param: IParams) {
		this._contestId = param?.contestId ?? 0;
	}
}
