import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

export const breakpointsTheme = createBreakpoints({
	values: {
		xs: 0,
		sm: 480,
		md: 900,
		lg: 1200,
		xl: 1536,
	},
});
