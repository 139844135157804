import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface INotificationBar {
	sport: string;
	isEnabled: boolean;
	message: string;
	hideLeaderboard:boolean;
}

interface IGetNotificationBar {
	game: string;
}

export interface INotificationBarStore {
	
	get notificationBar(): INotificationBar;

	fetchNotificationBar(params: IGetNotificationBar): Promise<INotificationBar>;

	clear(): void;
}

const _defaultNotificationBar = {
	sport: "",
	isEnabled: false,
	message: "",
	hideLeaderboard:false
};

@injectable()
export class NotificationBarStore implements INotificationBarStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _notificationBar: INotificationBar = _defaultNotificationBar;
	

	get notificationBar(): INotificationBar {
		return this._notificationBar;
	}

	clear() {
		this._notificationBar = {
			..._defaultNotificationBar,
		};
	}

	
	async fetchNotificationBar(params: IGetNotificationBar): Promise<INotificationBar> {
		
		const response = await this._jsonProvider.notificationBar(params.game);
		
		if (response.data) {
			
			runInAction(() => {
				
				this._notificationBar = response.data;
			});
		}

		return response.data;
	}
}
