import {inject, injectable} from "inversify";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";
import {IUserStats} from "data/types/user_stats";

export interface IUserStatsProvider {
	get: (lang: Language) => Promise<AxiosResponse<IApiResponse<IUserStats>>>;
}

@injectable()
export class UserStatsProvider implements IUserStatsProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	get = (lang: Language) =>
		this._http.get<IApiResponse<IUserStats>>(`${lang}/predictor/contests/user-stats`);
}
