import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Locale} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, runInAction} from "mobx";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";

import {ICompetition} from "data/types/competitions";
import {isDisabledSport} from "data/utils";

type IITerribleType = ICompetition;

export interface IBootstrapController extends ViewController {
	get isReady(): boolean;
	get competetions(): IITerribleType[];
	get competitionsNotDisabled(): IITerribleType[];
}

@injectable()
export class BootstrapController implements IBootstrapController {
	private _userLocale: Locale = Locale.EN_US;

	constructor(
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore
	) {
		makeAutoObservable(this);
	}

	private _isReady = false;

	get isReady(): boolean {
		return this._isReady;
	}

	get competetions() {
		// return compeitions (sports that are not hidden in env file)
		// return this._competitionsStore.list.filter((obj) => !DISABLED_SPORTS.includes(obj.sport));

		return this._competitionsStore.list;
	}

	get competitionsNotDisabled() {
		// return compeitions (sports that are not hidden in env file)
		// return this._competitionsStore.list.filter((obj) => !DISABLED_SPORTS.includes(obj.sport));
		return this._competitionsStore.list.filter((comp) => !isDisabledSport(comp.sport));
	}

	dispose(): void {
		return;
	}

	async init() {
		try {
			await this._i18nStore.switchLocale({
				locale: this.defineLocale(),
			});
		} catch (_err) {
			// Show error message to a user
		}

		runInAction(() => {
			this._isReady = true;
		});
	}

	/**
	 * The method is to define a user's locale. It can be done by:
	 * 1) navigator.language
	 * 2) Site URL
	 * 3) Some JSON or API request settings
	 * 4) Whatever else
	 */
	private defineLocale(): Locale {
		const url = new URLSearchParams(window.location.search);
		const localeFromURL = (url.get("lang") ?? "") as Locale;
		const isLocaleAllowed = [Locale.EN_US, Locale.PT_BR].some((it) =>
			it.toLowerCase().includes(localeFromURL.toLowerCase())
		);

		if (isLocaleAllowed && localeFromURL.length) {
			this._userLocale = localeFromURL;
		}

		return this._userLocale;
	}
}
