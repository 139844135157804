import React from "react";
import {Typography, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import styled from "@emotion/styled";

interface IProps {
	content: string;
	size?: string;
}

const TooltipSmall = styled(Tooltip)`
	width: 18px;
	@media (max-width: 680px) {
		width: 14px;
		padding-left: 2px;
	}
`;

export const CommonTooltip: React.FC<IProps> = ({content, size}) =>
	size === "small" ? (
		<TooltipSmall enterTouchDelay={0} title={<Typography fontSize={14}>{content}</Typography>}>
			<InfoIcon />
		</TooltipSmall>
	) : (
		<Tooltip enterTouchDelay={0} title={<Typography fontSize={14}>{content}</Typography>}>
			<InfoIcon style={{color: "var(--TAB-Masterbrand-Primary-TAB-Night-60)"}} />
		</Tooltip>
	);
