import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {WithTheme} from "data/types/generics";
import {useViewController} from "data/services/locator";
// import {IContestListController} from "views/pages/contest_list/contest_list.controller";
import {Bindings} from "data/constants/bindings";
import {useLocation, useNavigate} from "react-router-dom";
import {MenuBar} from "views/components/menu_bar/menu_bar.component";
import {Exist} from "views/components/exist/exist.component";
import {isAllTrue} from "data/utils";
import {ILandingController} from "views/pages/landing/landing.controller";
import {NotificationBar} from "views/components/notification_bar/NotificationBar.component";
import {GameBar} from "views/components/game_bar/game_bar.component";

const Wrapper = styled.header<WithTheme>`
	// background-color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
`;

interface IParams {
	game?: string | undefined;
}

export const TabHeader: React.FC<IParams> = observer(({game}) => {
	const navigate = useNavigate();
	// const {game} = useParams();

	// const controller = useViewController<IContestListController>(Bindings.ContestController, {
	// 	navigate: useNavigate(),
	// 	location: useLocation(),
	// });

	const landingCon = useViewController<ILandingController>(Bindings.LandingController, {
		navigate,
		game,
		location: useLocation(),
	});

	const location = useLocation();

	const pathSegments = location.pathname.split("/");
	const isThirdSegment = ["contest", "racing"].includes(pathSegments[3]);
	const isFirstSegment = ["games"].includes(pathSegments[2]);

	return (
		<Wrapper>
			<Exist when={landingCon.notificationBar}>
				<NotificationBar notificationBarProps={landingCon.notificationBar} />
			</Exist>
			{/* <Exist when={isAllTrue([location.pathname !== "/", controller.isLoggedIn])}> */}
			<Exist when={isAllTrue([location.pathname !== "/"])}>
				<MenuBar />
				<Exist when={!(isThirdSegment || isFirstSegment)}>
					<GameBar />
				</Exist>
			</Exist>
		</Wrapper>
	);
});
