import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaderboardItem} from "data/types/leaderboard";
import {RequestState} from "data/enums";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import type {ICommonLeaderboardController} from "views/components/leaderboard/leaderboard.controller";
import type {IContestStore} from "data/stores/contest/contest.store";
import {cloneDeep, last} from "lodash";
import {DEFAULT_LIST_SIZE} from "data/constants";

/*** TODO : this may not used anymore  ***/

type Params = Parameters<NonNullable<ICommonLeaderboardController["init"]>>[0];

export interface IOverallLeaderboardController extends ICommonLeaderboardController {
	get lastCompletedID(): number;

	get isOverallLeaderBoard(): boolean;

	setLeaderboardContestId: (leaderboardContestId: number | undefined) => void;
}

@injectable()
export class OverallLeaderboardController implements IOverallLeaderboardController {
	@observable private _contestId: number = 0;
	@observable private _isContestLeaderboard: boolean = false;
	@observable private _isGlobalLeaderboard: boolean = false;
	@observable private _game: string = "";
	@observable private _requestState: RequestState = RequestState.IDLE;
	private _currentPage = 1;
	private _limit: number = DEFAULT_LIST_SIZE;
	private _leaderboardContestId?: number;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardStore) public _leaderboardStore: ILeaderboardStore,
		@inject(Bindings.ContestStore) public _contestsStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return false;
	}

	get hasMore(): boolean {
		return this._leaderboardStore.leaderboard.nextPage;
	}

	get userId(): number {
		return this._leaderboardStore.leaderboard.userRanking?.userId || 0;
	}

	get list(): ILeaderboardItem[] {
		return this._leaderboardStore.leaderboard.rankings;
	}

	get listWithCurentUser(): ILeaderboardItem[] {
		let listWithoutCurrentUser = cloneDeep(this._leaderboardStore.leaderboard.rankings);
		const currentUser = this._leaderboardStore.leaderboard.userRanking;

		const isIncludingUser = listWithoutCurrentUser.some(function (item) {
			return item.userId === currentUser?.userId;
		});

		if (!isIncludingUser && currentUser) {
			listWithoutCurrentUser.unshift(currentUser);
		}

		if (isIncludingUser && currentUser) {
			listWithoutCurrentUser = listWithoutCurrentUser.filter(
				(item) => item.userId !== currentUser?.userId
			);
			listWithoutCurrentUser.unshift(currentUser);
		}

		return listWithoutCurrentUser;
	}

	get lastCompletedID() {
		return last(this._contestsStore.completedOrLiveContests)?.id || 0;
	}

	get isOverallLeaderBoard() {
		return !!this._isContestLeaderboard && !this._contestId;
	}

	onLoadMore = async () => {
		this._requestState = RequestState.PENDING;
		this._currentPage = this._currentPage + 1;

		try {
			if (!this._isGlobalLeaderboard) {
				await this._leaderboardStore.fetchContestLoadMore({
					page: this._currentPage,
					lang: this.i18n.lang,
					contestId: this._leaderboardContestId,
					limit: this._limit,
				});
			} else {
				await this._leaderboardStore.fetchGlobalLoadMore({
					page: this._currentPage,
					lang: this.i18n.lang,
					contestId: this._contestId,
					limit: this._limit,
				});
			}

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	};

	async init(param: Params) {
		this._contestId = param.contestId;
		this._isContestLeaderboard = !!param.isContestLeaderboard;
		this._isGlobalLeaderboard = !!param.isGlobalLeaderboard;
		if (!this._isGlobalLeaderboard && param?.leaderboardContestId) {
			this._leaderboardContestId = param.leaderboardContestId;
		}
		param.game && (this._game = param.game);

		this._requestState = RequestState.PENDING;
		await this._fetchLeaderboard();
	}

	setLeaderboardContestId = async (leaderboardContestId: number | undefined) => {
		this._leaderboardContestId = leaderboardContestId;
		// only send request if it is not global leaderboard
		if (!this._isGlobalLeaderboard && this._leaderboardContestId) {
			await this._fetchLeaderboard();
		}
	};

	private async _fetchLeaderboard() {
		this._requestState = RequestState.PENDING;
		try {
			if (!this._isGlobalLeaderboard && this._leaderboardContestId) {
				await this._leaderboardStore.fetchContest({
					lang: this.i18n.lang,
					contestId: this._leaderboardContestId,
					limit: this._limit,
				});
			}
			if (this._isGlobalLeaderboard) {
				await this._leaderboardStore.fetchGlobalLeaderBoard({
					lang: this.i18n.lang,
					limit: this._limit,
				});
			}
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}

	onChange(param: Params) {
		this._contestId = param.contestId;
	}
}
