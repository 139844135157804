import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IContestCountdownController} from "views/components/contest_countdown/contest_countdown.controller";
import {SportParam} from "data/enums";

interface IProps {
	contestId?: number;
	lockDate?: string;
	sport?: SportParam;
}

export const ContestCountdown: React.FC<IProps> = observer(({contestId, lockDate, sport}) => {
	const {timer, reset} = useViewController<IContestCountdownController>(
		Bindings.ContestCountdownController,
		{
			contestId,
			lockDate,
			sport,
		}
	);

	return reset ? <></> : <Fragment key={contestId}>{timer}</Fragment>;
});
