import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {isEqual} from "lodash";
import type {ChangeEvent, SyntheticEvent} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PASSWORD_REQUIREMENTS} from "data/constants";

interface IRegistrationFormElement extends HTMLFormElement {
	username: HTMLInputElement;
	email: HTMLInputElement;
	confirmEmail: HTMLInputElement;
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	terms: HTMLInputElement;
}

export interface IRegistrationController extends ViewController {
	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleValidatePassword: (event: ChangeEvent<HTMLInputElement>) => void;

	get isFormDisabled(): boolean;

	get error(): Record<string, string> | null;

	get displayName(): string;
}

@injectable()
export class RegistrationController implements IRegistrationController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	@observable private _displayName = "";

	get displayName(): string {
		return this._displayName;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();

		const {username, email, confirmEmail, password, confirmPassword, terms} =
			event.currentTarget;

		const validateList = [
			{field: email, error: "Please provide a valid email address", place: "email"},
			{
				field: confirmEmail,
				error: "'Confirm email' address is invalid",
				place: "confirmEmail",
			},
			{field: username, error: "Please provide your display name", place: "username"},
			{field: password, error: PASSWORD_REQUIREMENTS, place: "password"},
			{field: terms, error: "Please accept Terms & Conditions", place: "terms"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		if (email.value !== confirmEmail.value) {
			return this.reportError("Emails do not match", "confirmEmail");
		}

		if (password.value !== confirmPassword.value) {
			return this.reportError("Passwords do not match", "confirmPassword");
		}

		const payload = {
			username: username.value,
			email: email.value,
			password: password.value,
			lang: this._i18nStore.lang,
		};

		this._requestState = RequestState.PENDING;
		this._userStore.register(payload).catch(this.onError);
	};

	@action handleFormOnChange = (event: ChangeEvent<IRegistrationFormElement>) => {
		if (event.target.name !== "password") {
			this._errorMsg = null;
			this._errorPlace = "";
		}
	};

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action handleValidatePassword = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checkValidity()) {
			this._errorMsg = null;
		} else {
			this.reportError(PASSWORD_REQUIREMENTS, "password");
		}
	};

	dispose(): void {
		return;
	}

	init() {
		return;
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};
}
