import styled from "@emotion/styled";
import {Modal, Stack, IconButton, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {ReactNode} from "react";
import {ReactComponent as CloseSharpIcon} from "assets/img/x-icons.svg";
import {ReactComponent as WhitewhereOn} from "assets/img/white-were-on-tab.svg";
// import {ReactComponent as TABX} from "assets/img/TABXWhite.svg";
import {Exist} from "views/components/exist/exist.component";
import {
	ModalBox,
	ModalContent,
	ModalIconCloseButtonPosition,
} from "views/components/modals/modal_common.component";
import {ButtonPrimary} from "views/components/buttons/button.component";
import gradientX from "assets/img/gradient-x.png";

export const StackWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
	min-width: 450px;
	justify-content: space-between;

	@media (max-width: 830px) {
		min-width: unset;
	}
`;

export const Heading = styled(Typography)`
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	text-align: center;
	letter-spacing: 0.2px;
	color: var(--aflwModalHeader);
`;
export const SubHeading = styled(Typography)`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 130%;
	max-width: 494px;
`;

export const WhiteModalBox = styled(ModalBox)`
	border-radius: 5px;
	background: var(--tabx-neutrals-off-white);
	box-shadow: 0px 6px 12px 0px rgba(28, 28, 30, 0.2),
		0px 0.800000011920929px 1.5px 0px rgba(28, 28, 30, 0.1);
	// background-image: url(${gradientX});
	background-repeat: no-repeat;
`;

export const ChildWRapper = styled(Stack)`
	background: var(--aflwbkg-light-canvas);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	border-radius: 10px;
	strong {
		font-weight: 600;
	}
`;

export const IconWrap = styled.div`
	height: 50px;
	width: 50px;
`;

export const ModalStack = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	@media (max-width: 860px) {
		flex-direction: column;
		justify-content: center;
		gap: 12px;
	}
`;

export const CancelButton = styled(ButtonPrimary)`
	border: 1px solid var(--aflwPurple);
	color: var(--aflwPurple);
	width: 295px;
	max-width: 45%;
	@media (max-width: 640px) {
		max-width: 98%;
		width: 295px;
	}
`;
export const ConfirmButton = styled(ButtonPrimary)`
	background-color: var(--aflwModalHeader);
	width: 100%;
	max-width: 45%;
	@media (max-width: 640px) {
		max-width: 98%;
		width: 295px;
	}
`;

export const ModalTabContent = styled.div`
	margin: 24px 55px 0px 55px;
	width: 450px;
	.modal-title {
		color: var(--tabx-primary-dark-green);
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%; /* 32px */
		letter-spacing: 5px;
		text-transform: uppercase;
	}
	.accept,
	p {
		color: var(--tabx-neutrals-off-black, #111);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
	}
	.tnc {
		color: var(--tabx-neutrals-off-black, #111);
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 160%;
		text-decoration-line: underline;
	}
	.accept-butt {
		color: var(--tabx-neutrals-white);
		margin-top: 18px;
		width: 100%;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 25.6px */
		text-transform: uppercase;
		display: flex;
		height: 50px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background: var(--tabx-primary-tab-green);
	}
	@media (max-width: 820px) {
		width: 100%;
		.modal-title {
			font-size: 18px;
			font-weight: 600;
		}
	}
`;

export const ModalWrap = styled.div`
	color: var(--tabx-neutrals-off-black);
	h2 {
		color: var(--tabx-primary-dark-green);
	}

	.MuiOutlinedInput-root,
	.MuiInputLabel-root,
	.MuiOutlinedInput-notchedOutline {
		color: var(--tabx-primary-tab-green);
		border-color: var(--tabx-primary-tab-green);
	}
	input {
		color: var(--tabx-neutrals-off-black);
	}
	.MuiFormHelperText-root {
		color: red;
	}
`;

const FooterWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	background: var(--tabx-primary-tab-green);
	height: 40px;
	align-items: center;
	border-radius: 0px 0px 5px 5px;
`;
const ModalFooter: React.FC = () => {
	return (
		<FooterWrapper>
			<WhitewhereOn />
		</FooterWrapper>
	);
};

interface IProps {
	children?: ReactNode;
	icon?: ReactNode;
	heading?: string;
	subHeading?: string | ReactNode;
	confirmText?: string;
	isVisible: boolean;
	extraActionText?: string;
	showCancel: boolean;
	showCloseIcon?: boolean;
	cancelText?: string;
	showOtherFunction?: boolean;
	otherFunctionText?: string;
	onClick?: () => void;
	onClose?: () => void;
	otherFunction?: () => void;
}

export const ModalGeneral: React.FC<IProps> = observer(
	({
		children,
		confirmText,
		showCancel,
		isVisible,
		showOtherFunction,
		otherFunctionText,
		onClose,
		onClick,
		otherFunction,
		cancelText = "Cancel",
		showCloseIcon = true,
	}) => {
		if (!isVisible) {
			return null;
		}

		return (
			<Modal open={true} onClose={onClose}>
				<WhiteModalBox>
					<ModalContent>
						<Exist when={showCloseIcon}>
							<ModalIconCloseButtonPosition>
								<IconButton data-desc="modal-close" onClick={onClose}>
									<CloseSharpIcon />
								</IconButton>
							</ModalIconCloseButtonPosition>
						</Exist>
						<StackWrapper>
							{/* <TABX /> */}
							<Exist when={children}>
								<ChildWRapper>{children}</ChildWRapper>
							</Exist>
							<ModalStack>
								<Exist when={Boolean(showCancel)}>
									<CancelButton onClick={onClose}>{cancelText}</CancelButton>
								</Exist>
								<Exist when={Boolean(showOtherFunction)}>
									<CancelButton onClick={otherFunction}>
										{otherFunctionText}
									</CancelButton>
								</Exist>
								<Exist when={Boolean(confirmText)}>
									<ConfirmButton onClick={onClick}>{confirmText}</ConfirmButton>
								</Exist>
							</ModalStack>
						</StackWrapper>
					</ModalContent>
					<ModalFooter />
				</WhiteModalBox>
			</Modal>
		);
	}
);
