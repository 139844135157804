import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable, runInAction} from "mobx";
import type {ILeaderboardStore} from "data/stores/leaderboard/leaderboard.store";
import {ILeaderboardItem} from "data/types/leaderboard";
import {RequestState} from "data/enums";
import {DEFAULT_LIST_SIZE} from "data/constants";
import {cloneDeep} from "lodash";

export interface IGlobalLeaderboardController extends ViewController {
	i18n: ILocalizationStore;

	onLoadMore: () => Promise<void>;

	get isLoading(): boolean;

	get userId(): number;

	get hasMore(): boolean;

	get list(): ILeaderboardItem[];
}

@injectable()
export class GlobalLeaderboardController implements IGlobalLeaderboardController {
	@observable private _isOpenMobileMenu = false;
	@observable private _requestState: RequestState = RequestState.IDLE;

	private _currentPage = 1;
	private _limit: number = DEFAULT_LIST_SIZE;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardStore) public _leaderboardStore: ILeaderboardStore
	) {
		makeAutoObservable(this);
	}

	get hasMore(): boolean {
		return this._leaderboardStore.leaderboard.nextPage;
	}

	get list(): ILeaderboardItem[] {
		//return this._leaderboardStore.leaderboard.rankings;
		const listWithoutCurrentUser = cloneDeep(this._leaderboardStore.leaderboard.rankings);
		const currentUser = this._leaderboardStore.leaderboard.userRanking;

		const isIncludingUser = listWithoutCurrentUser.some(function (item) {
			return item.userId === currentUser?.userId;
		});

		if (!isIncludingUser && currentUser) {
			listWithoutCurrentUser.unshift(currentUser);
		}

		// if (isIncludingUser && currentUser) {
		// 	listWithoutCurrentUser = listWithoutCurrentUser.filter(
		// 		(item) => item.userId !== currentUser?.userId
		// 	);
		// 	listWithoutCurrentUser.unshift(currentUser);
		// }

		return listWithoutCurrentUser;
	}

	get userId(): number {
		return this._leaderboardStore.leaderboard.userRanking?.userId || 0;
	}

	get isLoading(): boolean {
		return this._requestState === RequestState.PENDING;
	}

	onLoadMore = async () => {
		this._requestState = RequestState.PENDING;
		this._currentPage = this._currentPage + 1;
		try {
			await this._leaderboardStore.fetchGlobalLoadMore({
				page: this._currentPage,
				lang: this.i18n.lang,
				limit: this._limit,
			});
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	};
	dispose(): void {
		return;
	}

	async init(): Promise<void> {
		try {
			await this._leaderboardStore.fetchGlobalLeaderBoard({
				page: this._currentPage,
				lang: this.i18n.lang,
				limit: this._limit,
			});
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}
}
