import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IContestStore} from "data/stores/contest/contest.store";
import {ContestStatusEnum, SportParam} from "data/enums";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;

interface IProps {
	contestId?: number;
	sport: SportParam;
}

type IChecksumAction = Record<keyof IChecksums, () => Promise<unknown>>;

export interface ILiveScoreController extends ViewController<IProps> {
	subscribeLiveScoring: (location?: string) => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	@observable protected _interval?: ReturnType<typeof setInterval>;
	protected readonly _actions: IChecksumAction;
	@observable protected _isSubscribed: boolean = false;
	@observable _sport: SportParam = SportParam.HR;

	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	get updatedChecksum(): IChecksums {
		return this._checksumStore.changedChecksums;
	}

	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this._checksumStore.fetchChecksums();
		this.callActions();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
			this.callActions();
		}, LIVE_SCORING_FETCH_TIMING);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	callActions() {
		Object.keys(this.updatedChecksum).forEach((key) => {
			const action = this._actions[key];
			if (action && typeof action === "function") {
				void action();
			}
		});
	}

	dispose(): void {
		this.unsubscribeLiveScoring();
	}

	init({contestId, sport}: IProps): void {
		this._sport = sport;
		let currentContest;
		if (this._contestStore.list.length > 0) {
			currentContest = this._contestStore.list.find((item) => item.id === contestId);
		}
		if (currentContest && currentContest.status === ContestStatusEnum.LIVE) {
			this.subscribeLiveScoring();
		}
	}

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private generateActions(): IChecksumAction {
		return {
			"contests-current-week": () => this._contestStore.fetchList(),
			contests: () => this._contestStore.getActiveList(this._sport),
			rounds: () => this._roundsStore.fetchRounds(),
		};
	}
}
