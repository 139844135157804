import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";

export interface IForgotPasswordPayload {
	email: string;
	lang: Language;
}

export interface IResetPasswordPayload {
	token: string;
	password: string;
	lang: Language;
}

export interface IPasswordApiProvider {
	forgotPassword: (params: IForgotPasswordPayload) => Promise<AxiosResponse>;
	resetPassword: (params: IResetPasswordPayload) => Promise<AxiosResponse>;
}

@injectable()
export class PasswordApiProvider implements IPasswordApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	forgotPassword = ({lang, ...params}: IForgotPasswordPayload) =>
		this._http.post<void>(`${lang}/password_reset/request`, params);
	resetPassword = ({lang, ...params}: IResetPasswordPayload) =>
		this._http.post<void>(`${lang}/password_reset`, params);
}
