import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {type IContestStore} from "data/stores/contest/contest.store";
import {ContestStatusEnum, QuestionStatusEnum, RequestState} from "data/enums";
import {ChipProps} from "@mui/material/Chip/Chip";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";
import {IContestsUserStat} from "data/types/user_stats";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {QuestionUtils} from "data/utils/question";
import type {IUserStore} from "data/stores/user/user.store";
import {find, get} from "lodash";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";

interface IParams {
	contestId: number;
	navigate: ReturnType<typeof useNavigate>;
	game: string | undefined;
}

export interface IContestItemController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	goToQuestions: (game: string) => void;

	get competitionLongName(): string;

	get contestName(): string;

	get contestListName(): string;

	get competitionName(): string;

	get contestWinner(): string | undefined;

	get statusLabel(): string;

	get statusColor(): ChipProps["color"];

	get contestStatus(): ContestStatusEnum;

	get correctPicks(): number | null;

	get yourPoints(): number | null;

	get yourRank(): number | null;

	get potentialPoints(): number | null;

	get selectedPicks(): number | null;

	get isEditMode(): boolean;
	get isAFL(): boolean;
	get isNRL(): boolean;
	get isSportTypeEvent(): boolean;
	get isContestComplete(): boolean;
	get isContestLiveUserPicked(): boolean;
	get isGenerateResultsLink(): boolean;

	get isAllQuestionsComplete(): boolean;
	get isAllQuestionsLock(): boolean;
	get stats(): IContestsUserStat | undefined;
	get hasPicks(): boolean;
	get isLoading(): boolean;

	get copyText(): string;
	setGame: (game: string) => void;
	setContestId: (contestId: number) => void;
}

@injectable()
export class ContestItemController implements IContestItemController {
	private _navigate: IParams["navigate"] | null = null;
	private _contestID: number = 0;
	@observable _game: string = "";
	@observable _requestState = RequestState.IDLE;

	private DEFAULT_ACTION = "View Picks";

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore
	) {
		makeAutoObservable(this);
	}

	setGame(game: string) {
		this._game = game;
	}

	setContestId(contestId: number) {
		this._contestID = contestId;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}
	get contestName() {
		return this.contestList?.name ?? "-";
	}

	get contestListName() {
		return this.contestList?.name ?? "-";
	}

	get competitionLongName() {
		const selectedCompetition = find(
			this._competitionsStore.list,
			(competition) => competition.name === this._game.toUpperCase()
		);
		if (selectedCompetition) {
			return selectedCompetition.longName;
		}
		return "";
	}

	get competitionName() {
		if (!this.contestList) {
			return "-";
		}

		const comp = this._contestStore.getByIDFromList(this._contestID);

		return comp?.name || "-";
	}

	get contestWinner() {
		const newdata: string[] = [];

		if (get(this.contestList, "winners", []).length > 3) {
			for (let i = 0; i < 2; i += 1) {
				newdata.push(get(this.contestList, "winners", [])[i]);
			}
			return `${newdata.join(", ")}.. ${get(this.contest, "winners", []).length - 2} more`;
		} else {
			return get(this.contestList, "winners", []).join(", ");
		}
	}

	get statusLabel() {
		if (this.contest) {
			return {
				[ContestStatusEnum.SCHEDULED]: "Scheduled",
				[ContestStatusEnum.LIVE]: "Live",
				[ContestStatusEnum.COMPLETE]: "Complete",
				[ContestStatusEnum.OPEN]: "Open",
			}[this.contest.status];
		}
		return "-";
	}

	get stats() {
		return this._userStatsStore.getByID(this._contestID);
	}

	get statusColor() {
		if (this.contestList) {
			const colors: Record<ContestStatusEnum, ChipProps["color"]> = {
				[ContestStatusEnum.LIVE]: "error",
				[ContestStatusEnum.COMPLETE]: "secondary",
				[ContestStatusEnum.OPEN]: "primary",
				[ContestStatusEnum.SCHEDULED]: "secondary",
			};
			return colors[this.contestList.status];
		}
	}

	get contestStatus() {
		return this.contestList?.status ?? ContestStatusEnum.OPEN;
	}

	get correctPicks() {
		return get(this.stats, "correctAnswersCount", null);
	}

	get yourPoints() {
		return this.stats?.points || null;
	}

	get yourRank() {
		return this.stats?.rank || null;
	}

	get potentialPoints() {
		return this.stats?.potentialPoints || null;
	}

	get selectedPicks() {
		return this.stats?.answersCount || null;
	}

	get hasPicks() {
		return (this.stats?.answersCount ?? 0) > 0;
	}

	get isEditMode() {
		const hasOpenQuestions = this.contest?.questions.find(QuestionUtils.IS_OPEN);
		return !this.stats?.answersCount && !hasOpenQuestions;
	}

	get isAllQuestionsComplete() {
		const questions = get(this.contest, "questions", []);
		const questionLength = questions.length;
		return (
			questionLength ===
			questions.filter((item) => item.status === QuestionStatusEnum.COMPLETE).length
		);
	}

	get isGames() {
		return this._game.toLocaleLowerCase() === "games";
	}
	get isAllQuestionsLock() {
		const questions = get(this.contest, "questions", []);
		return questions.every((item) => item.status !== QuestionStatusEnum.OPEN);
	}

	get isHr() {
		return this._game === "HR";
	}
	get isGh() {
		return this._game === "GH";
	}

	get isSportTypeEvent() {
		return ["HR", "GH"].includes(this._game);
	}

	get isAFL() {
		return this._game === "AFL";
	}

	get isNRL() {
		return this._game === "NRL";
	}

	get isContestComplete() {
		return this.contestStatus === ContestStatusEnum.COMPLETE;
	}

	get isContestLiveUserPicked() {
		return this.contestStatus === ContestStatusEnum.LIVE && this.isEditMode;
	}

	get isGenerateResultsLink() {
		return (
			this.isContestComplete || this.isContestLiveUserPicked || this.isAllQuestionsComplete
		);
	}

	private get contest() {
		return this._contestStore.getByID(this._contestID);
	}

	private get contestList() {
		return this._contestStore.getByIDFromList(this._contestID);
	}

	get copyText(): string {
		if (this.contestStatus === ContestStatusEnum.COMPLETE) {
			return this.i18n.t("contest.item.view_selections", "View Results");
		}

		if (this.contestStatus === ContestStatusEnum.SCHEDULED) {
			return "OPENS SOON";
		}

		if (
			this.contestStatus === ContestStatusEnum.OPEN ||
			this.contestStatus === ContestStatusEnum.LIVE
		) {
			return this.isAllQuestionsLock
				? this.DEFAULT_ACTION
				: this.hasPicks
				? "Edit Picks"
				: "Make Picks";
		}

		return this.DEFAULT_ACTION;
	}

	async init(param: IParams) {
		this._requestState = RequestState.PENDING;
		try {
			await this._userStatsStore.fetch(this.i18n.lang);
			this._requestState = RequestState.SUCCESS;
		} catch (e) {
			console.log(e);
			this._requestState = RequestState.ERROR;
		}

		this._navigate = param.navigate;
		this._contestID = param.contestId;
		if (param.game) {
			this._game = param.game;
		}
	}

	goToQuestions = (game: string) => {
		this._game = game;
		const gamePath = game.toLocaleLowerCase().trim();
		if (!this._userStore.isAuthorized) {
			this._userStore.requestLogin();
			return;
		}

		this._handleScriptTags();
		this._navigateToContest(gamePath);
	};

	_handleScriptTags = () => {
		if (this.isAFL) {
			createConnextraScriptTag(ConnextraType.AFL_PICK_START);
		}
		if (this.isNRL) {
			createConnextraScriptTag(ConnextraType.NRL_PICK_START);
		}
	};

	_navigateToContest = (gamePath: string) => {
		if (this.isHr || this.isGh) {
			this._navigate?.(`/sport/${gamePath}/racing/${this._contestID}`);
		} else if (this.isGames) {
			this._navigate?.(`/sport/${gamePath}/event/${this._contestID}`);
		} else if (this.isGenerateResultsLink) {
			this._navigate?.(`/sport/${gamePath}/contest/result/${this._contestID}`);
		} else {
			this._navigate?.(`/sport/${gamePath}/contest/${this._contestID}`);
		}
	};
}
