import {IContest, IQuestion} from "data/types/contests";
import {QuestionStatusEnum, QuestionTypeEnum} from "data/enums";

export class QuestionUtils {
	static IS_TIE_BREAKER = (question: IQuestion) => {
		return question.type === QuestionTypeEnum.TIEBREAKER;
	};

	static IS_OPEN = (question: IQuestion) => {
		return question.status === QuestionStatusEnum.OPEN;
	};

	static IS_FIRST_LOCKED(contest?: IContest) {
		return !!contest?.questions.find((question) => question.status !== QuestionStatusEnum.OPEN);
	}
	static IS_FIRST_COMPLETE(contest?: IContest) {
		return !!contest?.questions.find(
			(question) => question.status === QuestionStatusEnum.COMPLETE
		);
	}
}
