import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalMessage, IModalsStore} from "data/stores/modals/modal.store";

export interface IModalErrorController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalMessage | null;

	close: () => void;
}

@injectable()
export class ModalErrorController implements IModalErrorController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.ERROR;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	close = () => {
		this._modalsStore.hideModal();
	};
}
