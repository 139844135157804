import styled from "@emotion/styled";
import {Button} from "@mui/material";
import {ContestStatusEnum} from "data/enums";

export const ButtonPrimary = styled(Button)`
	margin: unset;
	min-width: 60px;
	width: auto;
	height: 50px;
	color: var(--tabx-neutrals-white, #fff);
	font-family: Graphik;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 160%; /* 25.6px */
	text-transform: uppercase;
	border-radius: 4px;
	align-items: center;
	border-radius: 4px;
	opacity: 0.9;
	background: var(--TAB-Masterbrand-Primary-TAB-Night-60);

	:hover {
		border: 2px solid var(--TAB-Masterbrand-Primary-TAB-Neon-60);
		background: var(--TAB-Masterbrand-Primary-TAB-Neon-60);
		color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
	}
	&:disabled {
		cursor: default;
		color: var(--TAB-Masterbrand-Secondary-TAB-Grey-80);
		border: 2px solid var(--tabx-neutrals-light-grey);
		background: var(--TAB-Input-Border);
		opacity: 0.6;
	}

	&:disabled::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100px;
		background: var(--aflwDisabledButtonHoverColor);
	}

	svg {
		margin-left: 8px;
		height: 24px;
		width: 24px;
	}

	@media (max-width: 900px) {
		border-radius: 4px;
		opacity: 0.9;
		min-width: auto;
		height: 40px;
	}
`;

export const PicksButton = styled(ButtonPrimary)<{status?: ContestStatusEnum; sport?: string}>`
	height: 50px;
	font-size: 16px;
	font-weight: 600;
	line-height: 160%;
	text-transform: uppercase;
	border-radius: 4px;
	color: ${({sport}) => (sport === "games" ? `#000;` : `var(--tabx-neutrals-white);`)}
	margin: 0;
	${({status, sport}) => {
		switch (status) {
			default:
				return sport === "games"
					? `	
				background: #000;
				color: var(--TAB-Masterbrand-Secondary-White, var(--Segmented-Button-Default, #FFF));
				`
					: `background: var(--TAB-Masterbrand-Secondary-TAB-Grey-40, #EAEEEA);
				 color:var(--TAB-Masterbrand-Secondary-TAB-Grey-80, #2B2E2B);`;
			case ContestStatusEnum.OPEN:
				return sport === "games"
					? `	
				background: #000;
				color: var(--TAB-Masterbrand-Secondary-White, var(--Segmented-Button-Default, #FFF));
				`
					: `
					border-radius: 4px;
					background: var(--TAB-Masterbrand-Primary-TAB-Night-60);
					color: var(--tabx-neutrals-white);
				`;
			case ContestStatusEnum.LIVE:
				return `
				border-radius: 4px;		
				background: #000;
				color: var(--TAB-Masterbrand-Secondary-White, var(--Segmented-Button-Default, #FFF));
				`;
			case ContestStatusEnum.COMPLETE:
				return sport === "games"
					? `	border: 2px solid #000;
					background:unset;
					color:#000;
				`
					: `	border-radius: 4px;
					border: 2px solid var(--TAB-Masterbrand-Primary-TAB-Night-60);
					background: var(--TAB-Night-20);
					color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
				`;
		}
	}}

	&:disabled {
		background: var(--TAB-Masterbrand-Secondary-TAB-Grey-40, #eaeeea);
		color: var(--TAB-Masterbrand-Secondary-TAB-Grey-80, #2b2e2b);
		opacity: 1;
	}

	:hover,
	&.active {
		background: #000;
		color: var(--TAB-Masterbrand-Secondary-White, var(--Segmented-Button-Default, #FFF));
		border: none;
	}
`;
