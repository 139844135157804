import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardProvider} from "data/providers/leaderboard/leaderboard.provider";
import type {ILeaderboard, IGetLeaderboard} from "data/types/leaderboard";

export interface ILeaderboardStore {
	get leaderboard(): ILeaderboard;

	fetch(params: IGetLeaderboard): Promise<ILeaderboard>;

	fetchLoadMore(params: IGetLeaderboard): Promise<ILeaderboard>;

	fetchOverallLoadMore(params: IGetLeaderboard): Promise<ILeaderboard>;

	fetchGlobalLoadMore(params: IGetLeaderboard): Promise<ILeaderboard>;

	fetchContest(params: IGetLeaderboard): Promise<ILeaderboard>;

	fetchContestLoadMore(params: IGetLeaderboard): Promise<ILeaderboard>;

	fetchGlobalLeaderBoard(params: IGetLeaderboard): Promise<ILeaderboard>;

	fetchOverallLeaderBoard(params: IGetLeaderboard): Promise<ILeaderboard>;

	clear(): void;
}

const _defaultLeaderboard = {
	nextPage: false,
	rankings: [],
	userRanking: null,
};

@injectable()
export class LeaderboardStore implements ILeaderboardStore {
	constructor(
		@inject(Bindings.LeaderboardProvider) private _leaderboardProvider: ILeaderboardProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _leaderboard: ILeaderboard = _defaultLeaderboard;

	get leaderboard(): ILeaderboard {
		return this._leaderboard;
	}

	clear() {
		this._leaderboard = {
			..._defaultLeaderboard,
		};
	}

	async fetch(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getOverall(params);

		if (response.data.success) {
			runInAction(() => {
				this._leaderboard = response.data.success;
			});
		}

		return response.data.success;
	}

	async fetchGlobalLeaderBoard(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getGlobalLeaderBoard(params);

		if (response.data.success) {
			runInAction(() => {
				this._leaderboard = response.data.success;
			});
		}

		return response.data.success;
	}

	async fetchOverallLeaderBoard(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getOverallLeaderBoard(params);

		if (response.data.success) {
			runInAction(() => {
				this._leaderboard = response.data.success;
			});
		}

		return response.data.success;
	}

	async fetchLoadMore(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getOverall(params);

		if (response.data.success) {
			runInAction(() => {
				this._leaderboard = {
					...response.data.success,
					rankings: [...this.leaderboard.rankings, ...response.data.success.rankings],
				};
			});
		}

		return response.data.success;
	}

	async fetchOverallLoadMore(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getOverallLeaderBoard(params);

		if (response.data.success) {
			const userIdArray = this.leaderboard.rankings.map((ranking) => ranking.userId);
			const filterNewArray = response.data.success.rankings.filter(
				(ranking) => !userIdArray.includes(ranking.userId)
			);
			runInAction(() => {
				this._leaderboard = {
					...response.data.success,
					rankings: [...this.leaderboard.rankings, ...filterNewArray],
				};
			});
		}

		return response.data.success;
	}

	async fetchGlobalLoadMore(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getGlobalLeaderBoard(params);
		if (response.data.success) {
			const userIdArray = this.leaderboard.rankings.map((ranking) => ranking.userId);
			const filterNewArray = response.data.success.rankings.filter(
				(ranking) => !userIdArray.includes(ranking.userId)
			);
			runInAction(() => {
				this._leaderboard = {
					...response.data.success,
					rankings: [...this.leaderboard.rankings, ...filterNewArray],
				};
			});
		}

		return response.data.success;
	}

	async fetchContest(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getContest(params);

		if (response.data.success) {
			runInAction(() => {
				this._leaderboard = response.data.success;
			});
		}

		return response.data.success;
	}

	async fetchContestLoadMore(params: IGetLeaderboard): Promise<ILeaderboard> {
		const response = await this._leaderboardProvider.getContest(params);

		if (response.data.success) {
			const userIdArray = this.leaderboard.rankings.map((ranking) => ranking.userId);
			const filterNewArray = response.data.success.rankings.filter(
				(ranking) => !userIdArray.includes(ranking.userId)
			);
			runInAction(() => {
				this._leaderboard = {
					...response.data.success,
					rankings: [...this.leaderboard.rankings, ...filterNewArray],
				};
			});
		}

		return response.data.success;
	}
}
