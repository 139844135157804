import {IUser} from "data/stores/user/user.store";

export enum ConnextraType {
	HOME = "f2p_pickem_Homepage",
	AFL_PICK_START = "f2p_pickem_aflpickstart",
	AFL_PICK_CONFIRM = "f2p_pickem_aflpickconfirm",
	NRL_PICK_START = "f2p_pickem_nrlpickstart",
	NRL_PICK_CONFIRM = "f2p_pickem_nrlpickconfirm",
	LANDING = "f2p_pickem_loggedin",
}

const CONNEXTRA_URL = `https://zz.connextra.com/dcs/tagController/tag/38d4aa698a70/`;

export const createConnextraScriptTag = (type: ConnextraType, user?: IUser) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (user?.id) {
		url.searchParams.append("userID", `${user.id}`);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
