import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable, runInAction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {IContest, IQuestion} from "data/types/contests";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import {size} from "lodash";
import {RequestState, QuestionStatusEnum} from "data/enums";
import {QuestionUtils} from "data/utils/question";
import {useNavigate} from "react-router-dom";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

interface IParams {
	contestID: number;
	questionId: number;
	competition: string | undefined;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IQuestionsController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	toNext: () => void;
	toPrev: () => void;
	hasAnswer: (questionId: number) => boolean;

	get isLoading(): boolean;
	get isContestComplete(): boolean;

	get isFirstQuestionLocked(): boolean;

	get questionIndex(): number;
	set questionIndex(index: number);

	get questionIndexForSlider(): number;

	get currentQuestion(): IQuestion | undefined;

	get questions(): IContest["questions"];
	get showOnlyFiveQuestions(): IContest["questions"];

	get hasUnSavedAnswers(): boolean;

	get isAllQuestionsAnswered(): boolean;
	get isShowEditConfirmModal(): boolean;
	get answersCount(): number;

	save(): void;
	confirmPicksClick: () => void;
	editPicksClick: () => void;
}

@injectable()
export class QuestionsController implements IQuestionsController {
	@observable private _contestID: number = 0;
	@observable private _competition: string = "";
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _navigate: IParams["navigate"] | null = null;
	@observable private _isShowEditConfirmModal: boolean = false;
	@observable private _questionIndexForSlider: number = 0;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	@observable private _questionIndex: number = 0;

	get questionIndex() {
		return this._questionIndex;
	}

	set questionIndex(index: number) {
		if (index < 0) {
			return;
		}

		const questionSize = size(this.questions);

		if (index > questionSize - 1) {
			return;
		}

		this._questionIndex = index;
	}

	get isShowEditConfirmModal() {
		return this._isShowEditConfirmModal;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isFirstQuestionLocked() {
		return QuestionUtils.IS_FIRST_LOCKED(this.contest);
	}

	get contest() {
		return this._contestStore.getByID(this._contestID);
	}

	get questions() {
		const questions = this.contest?.questions;

		if (!questions) {
			return [];
		}

		const tiebreakerQuestionID = questions.find(QuestionUtils.IS_TIE_BREAKER)?.id;

		if (this.isFirstQuestionLocked && tiebreakerQuestionID) {
			const hasTiebreakerAnswer = this._answersStore.getByID(tiebreakerQuestionID);

			if (!hasTiebreakerAnswer) {
				return questions.filter((question) => question.id !== tiebreakerQuestionID);
			}
		}

		return this.contest?.questions || [];
	}

	//the following three fuctions are for show Only Five Questions in Dots
	get questionIndexForSlider() {
		return this._questionIndexForSlider;
	}

	set questionIndexForSlider(questionIndexForSlider) {
		this._questionIndexForSlider = questionIndexForSlider;
	}

	get showOnlyFiveQuestions() {
		if (this.questions.length <= 5) {
			this.questionIndexForSlider = this.questionIndex;
			return this.questions;
		}
		if (this.questionIndex === 0 || this.questionIndex === 1) {
			this.questionIndexForSlider = this.questionIndex;
			return this.questions.slice(0, 5);
		}
		if (this.questionIndex === this.questions.length - 2) {
			this.questionIndexForSlider = 3;
			return this.questions.slice(-5);
		}
		if (this.questionIndex === this.questions.length - 1) {
			this.questionIndexForSlider = 4;
			return this.questions.slice(-5);
		}
		this.questionIndexForSlider = 2;
		return this.questions.slice(this.questionIndex - 2, this.questionIndex + 3);
	}

	get answersCount() {
		const answers = this._answersStore.list;
		return answers.length;
	}

	get isContestComplete() {
		const contest = this.contest;
		const questionsOpen = contest?.questions.filter(
			(question) => question.status === QuestionStatusEnum.OPEN
		);
		return questionsOpen?.length === 0;
	}

	get currentQuestion() {
		return this.questions[this._questionIndex];
	}

	get hasUnSavedAnswers() {
		return this._answersStore.hasChanges;
	}

	get isAllQuestionsAnswered() {
		const openQuestions = this.questions.filter(QuestionUtils.IS_OPEN);

		return !openQuestions.some((question) => !this._answersStore.getByID(question.id));
	}

	confirmPicksClick = () => {
		this._isShowEditConfirmModal = false;
		if (this._competition === "afl") {
			createConnextraScriptTag(ConnextraType.AFL_PICK_CONFIRM);
		}
		if (this._competition === "nrl") {
			createConnextraScriptTag(ConnextraType.NRL_PICK_CONFIRM);
		}
		// this.toResults();
		this._navigate?.(`/landing`);
	};

	editPicksClick = () => {
		this._isShowEditConfirmModal = false;
		this.toResults();
	};

	dispose(): void {
		this._answersStore.offAnswer(this.onAnswer);
	}

	async save() {
		this._requestState = RequestState.PENDING;
		try {
			this._isShowEditConfirmModal = true;
			await this._answersStore.save({
				contestId: this._contestID,
				lang: this.i18n.lang,
			});

			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}

	toResults = () => {
		this._navigate?.(`/sport/${this._competition}/contest/result/${this._contestID}`);
	};

	toNext = () => {
		this.questionIndex = this.questionIndex + 1;
	};

	onAnswer = () => {
		setTimeout(() => {
			this.questionIndex = this.questionIndex + 1;
		}, 300);
	};

	toPrev = () => {
		this.questionIndex = this.questionIndex - 1;
	};

	hasAnswer = (questionId: number) => {
		return !!this._answersStore.getByID(questionId);
	};

	init(params: IParams): void {
		this._contestID = params.contestID;
		if (params.competition) {
			this._competition = params.competition;
		}
		this._navigate = params.navigate;

		this.setQuestionById(params.questionId);

		this._requestState = RequestState.PENDING;

		void this._answersStore
			.fetch({
				contestId: params.contestID,
				lang: this.i18n.lang,
			})
			.then(() => {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
				});
			})
			.catch(() => {
				runInAction(() => {
					this._requestState = RequestState.ERROR;
				});
			});

		this._answersStore.onAnswer(this.onAnswer);
	}

	setQuestionById(questionId: number) {
		if (questionId) {
			this.questionIndex = this.questions.findIndex((question) => question.id === questionId);
			return;
		}

		const openQuestion = this.questions.findIndex(
			(it) => it.status === QuestionStatusEnum.OPEN
		);

		if (openQuestion > -1) {
			this.questionIndex = openQuestion;
		} else {
			this.questionIndex = 0;
		}
	}
}
