import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {ILoginPayload} from "data/providers/api/auth.api.provider";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {extractErrorMessage} from "data/utils";

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
}

export interface IBackdoorController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<ILoginForm>) => void;
	login: (params: ILoginPayload) => Promise<void>;
	handleFormOnChange: () => void;

	get error(): Record<string, string> | null;

	get isFormDisabled(): boolean;
}

@injectable()
export class BackdoorController implements IBackdoorController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action login(payload: ILoginPayload) {
		this._requestState = RequestState.PENDING;
		return this._userStore.login(payload).catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email} = event.currentTarget;

		if (!email.checkValidity()) {
			return this.reportError("Please provide a valid email address", "email");
		}

		void this.login({
			email: email.value,
			lang: this._i18nStore.lang,
		});
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.reportError(extractErrorMessage(error));
	};
}
