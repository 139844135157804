import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {
	ModalGeneral,
	ModalTabContent,
	ModalWrap,
} from "views/components/modals/modal_general.component";
import {get} from "lodash";

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	const title = get(modalContent, "title", "Error");
	const message = get(modalContent, "message", "");

	return (
		<ModalGeneral isVisible={isOpen} heading={""} showCancel={false} onClose={close}>
			<ModalTabContent>
				<ModalWrap>
					<div className="modal-title">{title}</div>
					<Exist when={message !== ""}>
						<p>{message}</p>
					</Exist>
				</ModalWrap>
			</ModalTabContent>
		</ModalGeneral>
	);
});
