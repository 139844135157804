import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modal.store";
import type {
	IAuthApiProvider,
	IAuthPayload,
	ILoginPayload,
} from "data/providers/api/auth.api.provider";
import type {
	IUserApiProvider,
	IRegistrationPayload,
	IUpdateUserPayload,
} from "data/providers/api/user.api.provider";
import type {
	IForgotPasswordPayload,
	IPasswordApiProvider,
	IResetPasswordPayload,
} from "data/providers/api/password.api.provider";
import type {AxiosResponse} from "axios";
import {Language, ModalType} from "data/enums";

export interface IUser {
	id: number;
	email: string;
	username: string;
	isNotificationsEnabled: boolean;
	acceptTerms: string[];
}

export interface IUserStore {
	requestLogin: () => void;

	get user(): IUser | undefined;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	forgotPassword(payload: IForgotPasswordPayload): Promise<AxiosResponse<void>>;

	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse<void>>;

	register(payload: IRegistrationPayload): Promise<void>;

	update(payload: IUpdateUserPayload): Promise<void>;

	deactivate(lang: Language): Promise<void>;

	login(payload: ILoginPayload): Promise<void>;

	logout(lang: Language): Promise<void>;

	requestUser(lang: Language): Promise<void>;

	emulateLogin: () => void;

	authenticate(payload: IAuthPayload): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _user?: IUser = undefined;

	get user() {
		return this._user;
	}

	@observable private _wasLoggedOut = false;

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get isAuthorized() {
		//return true;
		return Boolean(this.user);
	}

	@action private checkUserName = (user: IUser) => {
		if (user.username === "") {
			this._modalsStore.showModal(ModalType.GENERAL, {
				message: "show modal",
			});
		}
	};

	@action
	async requestUser(lang: Language) {
		try {
			const response = await this._userApi.user(lang);
			const {user} = response.data.success;

			this.checkUserName(user);
			runInAction(() => {
				this._user = user;
			});
		} catch (err) {
			console.log("unauthorized");
			return undefined;
		}
	}

	@action
	emulateLogin() {
		const user = {
			id: 1,
			email: "john.doe+1688813848@gmail.com",
			username: "Test User",
			isNotificationsEnabled: true,
			acceptTerms: [""],
		};
		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async authenticate(payload: IAuthPayload) {
		const response = await this._authApi.auhtenticate(payload);
		const {user} = response.data.success;

		this.checkUserName(user);
		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async login(payload: ILoginPayload) {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;
		this.checkUserName(user);
		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: IRegistrationPayload) {
		const response = await this._userApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: IUpdateUserPayload) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;
		if (user.username !== "") {
			this._modalsStore.hideModal();
		}
		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout(lang: Language) {
		await this._authApi.logout(lang);

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate(lang: Language) {
		await this._userApi.deactivate_account(lang);

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	forgotPassword(payload: IForgotPasswordPayload) {
		return this._passwordApi.forgotPassword(payload);
	}

	resetPassword(payload: IResetPasswordPayload) {
		return this._passwordApi.resetPassword(payload);
	}

	requestLogin() {
		alert("Logging...");
	}
}
