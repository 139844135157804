import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {IContestStore} from "data/stores/contest/contest.store";
import {ContestStatusEnum, GameName, QuestionStatusEnum} from "data/enums";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import {filter, get} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";
import {IContest} from "data/types/contests";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import {useLocation} from "react-router-dom";
import {getCardTitle} from "data/utils";

interface IParams {
	contestId?: number;
	location: ReturnType<typeof useLocation>;
}

export interface IGameBarController extends ViewController<IParams | void> {
	i18n: ILocalizationStore;

	get isComplete(): boolean;

	// get dashboardStats(): Record<keyof IDashboard, string | number>;

	get userName(): string;

	get contest(): IContest | undefined;

	get potentialPoints(): number;

	get contestPoints(): number | null;

	get contestRank(): number | null;

	get isAnyQuestionComplete(): boolean;

	get nextQuestionLockDate(): string | undefined;

	get totalNumQuestions(): number;
	get totalNumAnswered(): number;

	get isThisWeek(): boolean;

	get dashBoardStatusLastWeekPoints(): number | string;
	get dashBoardStatusCurrentWeekPoints(): number | string;
	get dashBoardStatusCurrentWeekContestCount(): number | string;
	get dashBoardStatusCurrentWeekContestEnteredCount(): number | string;

	get displayContestEnteredCount(): number | string;
	get displayTotalContestCount(): number | string;

	getGameName: (
		game: "afl" | "hr" | "nrl" | "nba" | "soo" | "games" | undefined,
		isMobile: boolean
	) => string;
}

@injectable()
export class GameBarController implements IGameBarController {
	@observable private _contestId = 0;
	private _location!: IParams["location"];

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestsStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore
	) {
		makeAutoObservable(this);
	}

	get isThisWeek(): boolean {
		return this._contestsStore.isThisWeek;
	}

	// get dashboardStats() {
	// 	return mapValues(this._dashboardStore.stats, (value) => {
	// 		return value === null ? "-" : value;
	// 	});
	// }

	get dashBoardStatusLastWeekPoints() {
		return this._dashboardStore.stats.previousWeekStats?.points || "-";
	}

	get dashBoardStatusCurrentWeekPoints() {
		return this._dashboardStore.stats.currentWeekStats?.points || "-";
	}

	get dashBoardStatusCurrentWeekContestCount() {
		return this._dashboardStore.stats.currentWeekStats?.totalContestsCount || "-";
	}

	get dashBoardStatusCurrentWeekContestEnteredCount() {
		return this._dashboardStore.stats.currentWeekStats?.enteredContestsCount || "-";
	}

	get displayContestEnteredCount() {
		return this.isThisWeek
			? this.dashBoardStatusCurrentWeekContestEnteredCount
			: this._dashboardStore.stats.previousWeekStats?.enteredContestsCount || "-";
	}

	get displayTotalContestCount() {
		return this.isThisWeek
			? this.dashBoardStatusCurrentWeekContestCount
			: this._dashboardStore.stats.previousWeekStats?.totalContestsCount || "-";
	}
	get userName() {
		return this._userStore.user?.username ?? "-";
	}

	get isComplete() {
		return this.contest?.status === ContestStatusEnum.COMPLETE;
	}

	get isAnyQuestionComplete() {
		return !!this.contest?.questions.find(
			(question) => question.status !== QuestionStatusEnum.OPEN
		);
	}

	get totalNumQuestions() {
		return this.contest?.questions.length || 0;
	}

	get totalNumAnswered() {
		return this._answersStore.list.length;
	}

	get nextQuestionLockDate() {
		const filteredOpen = filter(this.contest?.questions, {status: QuestionStatusEnum.OPEN});

		const sortedByLockDate = filteredOpen.sort((objA, objB) => {
			const dateA = new Date(objA.lockDate);
			const dateB = new Date(objB.lockDate);

			return dateA.getTime() - dateB.getTime();
		});

		const lookfor = (count: number = 0): string | undefined => {
			if (
				sortedByLockDate[count] &&
				new Date(sortedByLockDate[count]?.lockDate) > new Date()
			) {
				return sortedByLockDate[count]?.lockDate;
			} else if (count < sortedByLockDate.length - 1) {
				// Increment count and return the result of the recursive call
				return lookfor(count + 1);
			} else {
				return undefined;
			}
		};

		return lookfor();
	}

	get contest() {
		return this._contestsStore.getByID(this._contestId);
	}

	get potentialPoints() {
		/* changed to fetch value from API*/
		return this.contestStats?.potentialPoints || 0;
		// const BEPotentialPoints = get(this.contestStats, "potentialPoints", 0);
		// // not needed can get from API
		// const questions = this.contest?.questions || [];

		// return sumBy(this._answersStore.list, (answer) => {
		// 	const question = questions.find((question) => question.id === answer.questionId);
		// 	// if (question?.status !== QuestionStatusEnum.OPEN && answer.value !== question?.answer) {
		// 	// 	return 0;
		// 	// }
		// 	const option = question?.options.find((option) => option.id === answer.value);
		// 	return answer.points || option?.points || BEPotentialPoints || 0;
		// });
	}

	get contestStats() {
		return this._userStatsStore.getByID(this._contestId);
	}

	get contestPoints() {
		return this.contestStats?.points || null;
	}

	get contestRank() {
		return this.contestStats?.rank || null;
	}

	getGameName = (
		game: "afl" | "hr" | "nrl" | "nba" | "soo" | "games" | undefined,
		isMobile: boolean
	) => {
		let gameName = GameName.AFL as string;
		if (game) {
			gameName = isMobile ? game.toUpperCase() : getCardTitle(game);
		}
		return gameName;
	};

	init(param: IParams): void {
		const contestId = get(param, "contestId", 0);
		this._contestId = contestId;
		void this._dashboardStore.fetch({lang: this.i18n.lang});
		// void this._userStatsStore.fetch(this.i18n.lang);
	}

	onChange(param: IParams) {
		this._contestId = param?.contestId ?? 0;
	}
}
