import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";

import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IAuthPayload} from "data/providers/api/auth.api.provider";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {extractErrorMessage} from "data/utils";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import {isEmpty} from "lodash";

interface ILoginForm extends HTMLFormElement {
	code: HTMLInputElement;
	lastName: HTMLInputElement;
}

export interface ILoginController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<ILoginForm>) => void;
	onChangeInput: (e: React.ChangeEvent<ILoginForm>) => void;
	get error(): Record<string, string> | null;
	get errorMsg(): {__html: string} | undefined;

	get isFormDisabled(): boolean;
	get loginState(): RequestState;
	get isEmptyField(): boolean;
}

@injectable()
export class LoginController implements ILoginController {
	@observable _loginState: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _code = "";
	@observable private _lastame = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get loginState() {
		return this._loginState;
	}
	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}
	get errorMsg() {
		if (this._errorMsg) {
			return {
				__html: `User not found. Please contact the TAB customer support team <a href="https://help.tab.com.au/s/article/Customer-Service-telephone-details" target="_blank">HERE</a>`,
			};
		}
	}

	get isFormDisabled() {
		return this._loginState === RequestState.PENDING;
	}

	get isEmptyField() {
		return isEmpty(this._code) || isEmpty(this._lastame);
	}

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._loginState = RequestState.IDLE;
	};

	@action authenticate(payload: IAuthPayload) {
		this._loginState = RequestState.PENDING;
		return this._userStore.authenticate(payload).catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {code, lastName} = event.currentTarget;
		void this.authenticate({
			code: code.value,
			lastName: lastName.value,
			lang: this._i18nStore.lang,
		});
	};

	onChangeInput = (e: React.ChangeEvent<ILoginForm>) => {
		const {code, lastName} = e.currentTarget;
		this._code = code.value;
		this._lastame = lastName.value;
	};

	dispose(): void {
		return;
	}

	init(): void {
		createConnextraScriptTag(ConnextraType.HOME);
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;
		return true;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._loginState = RequestState.ERROR;

		this.reportError(extractErrorMessage(error));
	};
}
