import {compact, mapValues, keyBy} from "lodash";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

export const useDigitParams = <T extends string[]>(...keys: T): Record<T[number], number> => {
	const navigate = useNavigate();
	const params = useParams<Record<string, string>>();

	const keysWithValue = compact(
		keys.map((key) => ({
			value: params[key],
			key,
		}))
	);

	const hasInvalidParams = keysWithValue.some(
		(routeParam) => routeParam.value && !isFinite(+routeParam.value)
	);

	if (hasInvalidParams) {
		console.error(`The following params should be digit: ${keys.join(", ")}`);
	}

	useEffect(() => {
		if (hasInvalidParams) {
			navigate("../");
		}
	}, [hasInvalidParams, navigate]);

	const parsedValues = mapValues(keyBy(keysWithValue, "key"), (routeParam) => {
		return routeParam.value ? Number(routeParam.value) : 0;
	});

	return parsedValues as Record<T[number], number>;
};
