import styled from "@emotion/styled";
import React, {useEffect} from "react";
import {ReactComponent as MenuLogo} from "assets/img/TAB_logo.svg";
import {ReactComponent as MobileLogo} from "assets/img/TAB_logo_2024.svg";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {IconButton, List, ListItem, useMediaQuery} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {IHeaderController} from "views/components/header/header.controller";

const MobileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	width: 90%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9;
	background: var(--tabx-neutrals-off-white);
	transform: translateX(-100%);
	transition: transform 0.5s;

	&.open {
		transform: translateX(0);
	}
	overflow-y: scroll;
`;

const Div = styled.div`
	background: var(--TAB-Masterbrand-Secondary-TAB-Night-80);
`;

const Wrapper = styled.div`
	display: flex;
	max-width: 1284px;
	margin: 0 auto;
	padding: 0;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1180px) {
		padding: 0px 20px;
	}

	@media (max-width: 640px) {
		width: 100%;
		padding: 3px 20px;
	}
	z-index: 9;
`;

const InnerWrapper = styled.div`
	display: flex;
	width: 100%;
	padding: 16px 0;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	@media (max-width: 640px) {
		padding: 16px 10px;
	}

	@media (max-width: 375px) {
		padding: 0px;
	}
`;
const MobilMenuFooter = styled.div`
	display: flex;
	width: 100%;
	padding: 10px 16px;
	flex-direction: column;
	background: var(--TAB-Mobile-Item);
	height: auot;
`;
const TabLogo = styled(MenuLogo)`
	width: 155px;
	height: 50px;
	flex-shrink: 0;
	@media (max-width: 640px) {
		width: 124px;
		height: 40px;
		flex-shrink: 0;
	}
`;

const MobilMenuHeader = styled.div`
	width: 100%;
`;

const MenusWrapper = styled.ul`
	display: flex;
	align-items: center;
	gap: 12px;
	height: 50px;
	width: 100%;

	.active {
		color: var(--TAB-Masterbrand-Primary-TAB-Neon-60);
		border-bottom: 2px solid var(--TAB-Masterbrand-Primary-TAB-Neon-60);
		padding-bottom: 0;
	}

	& > :last-child {
		margin-left: auto;
	}
`;
const MennuLink = styled(NavLink)`
	color: var(--tabx-neutrals-off-white);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 160%;
	text-transform: uppercase;
	margin: 0 22px;
	padding-bottom: 2px;
	:hover {
		color: var(--TAB-Masterbrand-Primary-TAB-Neon-60);
		border-bottom: 2px solid var(--TAB-Masterbrand-Primary-TAB-Neon-60);
		padding-bottom: 0;
	}
`;

const MobileListItem = styled(ListItem)`
	background-color: var(--TAB-Mobile-Item);
	margin-bottom: 2px;
	color: var(--tabx-neutrals-off-black);
`;

const FirstMobileListItem = styled(MobileListItem)`
	background-color: var(--TAB-Masterbrand-Primary-TAB-Wattle-60);
	color: var(--tabx-neutrals-off-black);
`;

const MobileMenuLink = styled(NavLink)`
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 160%; /* 22.4px */
	text-transform: uppercase;
`;

const LogoButton = styled(IconButton)`
	padding: 0;
`;

const Externallink = styled.a`
	color: var(--tabx-neutrals-off-black);
	background: var(--TAB-Mobile-Item);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 160%;
	text-transform: uppercase;
	display: flex;
	height: 40px;
	padding: 6px 0px;
	justify-content: flex-start;
	align-items: center;
	flex-shrink: 0;
	width: 100%;
	gap: 8px;
`;

const MobileInnerWrapper = styled(InnerWrapper)`
	height: 66px;
	padding: 3px 20px 3px 10px;
	background: var(--TAB-Masterbrand-Primary-TAB-Night-60);
`;

export const MenuBar: React.FC = observer(() => {
	const {game} = useParams();
	const navigate = useNavigate();
	const {routes, controllerSport, updateSport, isMenuOpen, handleToggleMenu} =
		useViewController<IHeaderController>(Bindings.HeaderController, {
			sport: game,
			navigate: useNavigate(),
		});
	const location = useLocation();
	const isMobile = useMediaQuery(`(max-width: 900px)`);

	const menuClassName = isMenuOpen ? "open" : "";

	useEffect(() => {
		handleToggleMenu(false);
	}, [location, handleToggleMenu]);

	useEffect(() => {
		handleToggleMenu(false);
	}, [isMobile, handleToggleMenu]);

	useEffect(() => {
		if (controllerSport !== game) {
			updateSport(game);
		}
	}, [controllerSport, game, updateSport]);

	return (
		<Div>
			<Wrapper>
				<Exist when={isMobile}>
					<IconButton
						aria-label="menu"
						sx={{color: "var(--tabx-neutrals-white)", padding: 0, marginRight: "8px"}}
						onClick={() => handleToggleMenu()}>
						<MenuIcon />
					</IconButton>
				</Exist>

				<InnerWrapper>
					<LogoButton aria-label={"TAB Pick & Win"} onClick={() => navigate("/landing")}>
						<TabLogo />
					</LogoButton>
					{isMobile ? (
						<></>
					) : (
						<MenusWrapper>
							{routes.map((route) => (
								<li key={route.path}>
									<MennuLink to={route.path}>{route.label}</MennuLink>
								</li>
							))}
						</MenusWrapper>
					)}
				</InnerWrapper>
				<Exist when={isMobile}>
					<MobileWrapper className={`${menuClassName}`}>
						<MobilMenuHeader>
							<MobileInnerWrapper>
								<IconButton
									sx={{
										color: "var(--tabx-neutrals-white)",
										padding: "8px",
									}}
									onClick={() => handleToggleMenu()}>
									<CloseIcon />
								</IconButton>
								<MobileLogo />
							</MobileInnerWrapper>

							<List
								sx={{width: "100%", padding: "0"}}
								component="ul"
								aria-labelledby="nested-list-subheader">
								<FirstMobileListItem>
									<MobileMenuLink to={`/landing`}>MY PICKS</MobileMenuLink>
								</FirstMobileListItem>
								<MobileListItem>
									<MobileMenuLink to={`/leaderboard`}>LEADERBOARD</MobileMenuLink>
								</MobileListItem>
								<MobileListItem>
									<MobileMenuLink to={`/prizes`}>PRIZES</MobileMenuLink>
								</MobileListItem>
								<MobileListItem>
									<MobileMenuLink to={`/help`}>HELP</MobileMenuLink>
								</MobileListItem>
							</List>
						</MobilMenuHeader>
						<MobilMenuFooter>
							<Externallink href={"https://www.tab.com.au/"} target="_blank">
								TAB.COM.AU <OpenInNewIcon />
							</Externallink>
							<Externallink href={"/my-account"}>
								MY ACCOUNT <AccountCircleIcon />
							</Externallink>
						</MobilMenuFooter>
					</MobileWrapper>
				</Exist>
			</Wrapper>
		</Div>
	);
});
