import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modal.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {get} from "lodash";
import {action, observable, makeAutoObservable} from "mobx";
import {ModalType, RequestState} from "data/enums";
import {SyntheticEvent} from "react";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {ISessionController} from "views/components/session/session.controller";

interface IUseryName extends HTMLFormElement {
	username: HTMLInputElement;
}

export interface IModalUsernameController extends ViewController {
	get userName(): string;
	get userTerms(): string[];
	get isOpen(): boolean;
	get requestState(): RequestState;
	get isLoggedIn(): boolean;

	close: () => void;

	get error(): Record<string, string> | null;

	handleFormSubmit: (event: SyntheticEvent<IUseryName>) => void;
}

@injectable()
export class ModalUsernameController implements IModalUsernameController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.SessionController) private _session: ISessionController
	) {
		makeAutoObservable(this);
	}

	get requestState() {
		return this._requestState;
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	get userName() {
		return get(this._userStore.user, "username", "");
	}

	get userTerms() {
		return get(this._userStore.user, "acceptTerms", []);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.GENERAL;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "username"]: this._errorMsg,
		};
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IUseryName>) => {
		event.preventDefault();

		const {username} = event.currentTarget;

		const payload = {
			username: username.value,
			lang: this._i18nStore.lang,
		};

		this._requestState = RequestState.PENDING;
		this._userStore.update(payload).then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	close = () => {
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
