import React, {Fragment} from "react";
import {ModalTerms} from "views/components/modals/modal_terms.component";
import {ModalUsername} from "views/components/modals/modal_username.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";

export const Modals: React.FC = () => (
	<Fragment>
		<ModalTerms />
		<ModalUsername />
		<ModalError />
	</Fragment>
);
