import styled from "@emotion/styled";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import {Box, Typography} from "@mui/material";

export const ModalBox = styled(Box)`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 600px;
	min-width: 320px;
	background-color: var(--aflwBKGPurple);
	border-radius: 10px;

	@media (max-width: 860px) {
		max-width: 360px;
	}
`;

export const ModalContent = styled(Box)`
	padding: 20px;
	max-height: 85vh;
	overflow: auto;
	// FIX for iOS
	@supports (-webkit-touch-callout: none) {
		max-height: 75vh;
	}
`;

export const ModalErrorIcon = styled(ErrorSharpIcon)`
	width: 40px;
	height: 40px;
	margin: 16px auto;
	color: var(--iconErrorColor);
`;

export const ModalTitle = styled(Typography)`
	font-weight: 700;
	font-size: 20px;
	line-height: 20px;
`;

export const ModalDescription = styled(Typography)`
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	margin: 20px auto;
	text-align: center;
`;

export const ModalIconCloseButtonPosition = styled.div`
	position: absolute;
	right: 5px;
	top: 5px;
`;

export const ModalContentCustom = styled(ModalContent)`
	padding: 35px 20px;
`;
