/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 *
 * More info on custom theming for Typography can be found here
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
import {ThemeOptions} from "@mui/material/styles/createTheme";
import {TypographyStyleOptions} from "@mui/material/styles/createTypography";
import {breakpointsTheme} from "assets/theming/breakpoints.theme";

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		body1Book: true;
		body1Medium: true;
		body1Black: true;

		body2Book: true;
		body2Medium: true;
		body2Black: true;

		bodyCopyB1Book: true;
		bodyCopyB1Medium: true;
		bodyCopyB1Black: true;
		bodyCopyB2Book: true;
		bodyCopyB2Black: true;
		bodyCaption: true;
		bodyCaptionR: true;
	}
}

declare module "@mui/material/styles" {
	interface TypographyVariants {
		body1Book: TypographyStyleOptions;
		body1Medium: TypographyStyleOptions;
		body1Black: TypographyStyleOptions;
		body2Book: TypographyStyleOptions;
		body2Medium: TypographyStyleOptions;
		body2Black: TypographyStyleOptions;

		bodyCopyB1Book: TypographyStyleOptions;
		bodyCopyB1Medium: TypographyStyleOptions;
		bodyCopyB1Black: TypographyStyleOptions;
		bodyCopyB2Book: TypographyStyleOptions;
		bodyCopyB2Black: TypographyStyleOptions;
		bodyCaption: TypographyStyleOptions;
		bodyCaptionR: TypographyStyleOptions;
	}

	interface TypographyPropsVariantOverrides {
		body1Book: true;
		body1Medium: true;
		body1Black: true;

		body2Book: true;
		body2Medium: true;
		body2Black: true;

		bodyCopyB1Book: true;
		bodyCopyB1Medium: true;
		bodyCopyB1Black: true;
		bodyCopyB2Book: true;
		bodyCopyB2Black: true;
		bodyCaption: true;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		body1Book: TypographyStyleOptions;
		body1Medium: TypographyStyleOptions;
		body1Black: TypographyStyleOptions;
		body2Book: TypographyStyleOptions;
		body2Medium: TypographyStyleOptions;
		body2Black: TypographyStyleOptions;
		bodyCopyB1Book: TypographyStyleOptions;
		bodyCopyB1Medium: TypographyStyleOptions;
		bodyCopyB1Black: TypographyStyleOptions;
		bodyCopyB2Book: TypographyStyleOptions;
		bodyCopyB2Black: TypographyStyleOptions;
		bodyCaption: TypographyStyleOptions;
		bodyCaptionR: TypographyStyleOptions;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		body3: true;
		body4: true;
	}
}

export const typographyTheme: ThemeOptions = {
	typography: {
		fontFamily: "var(--fontFamilyHeadings)",
		h1: {
			fontWeight: 700,
			fontSize: "82px",
			fontFamily: "var(--fontFamilyHeadings)",
			lineHeight: 1,
			[breakpointsTheme.down("md")]: {
				fontSize: "48px",
			},
		},
		h2: {
			fontWeight: 700,
			fontSize: "36px",
			fontFamily: "var(--fontFamilyHeadings)",
			[breakpointsTheme.down("md")]: {
				fontSize: "36px",
			},
		},
		h3: {
			fontWeight: 700,
			fontSize: "28px",
			fontFamily: "var(--fontFamilyHeadings)",
			[breakpointsTheme.down("md")]: {
				fontSize: "24px",
			},
		},
		h4: {
			fontWeight: 700,
			fontSize: "20px",
			fontFamily: "var(--fontFamilyHeadings)",
			[breakpointsTheme.down("md")]: {
				fontSize: "18px",
			},
		},
		subtitle1: {
			fontSize: "32px",
			fontWeight: 700,
			lineHeight: 1,
			fontFamily: "var(--fontFamilyHeadings)",
			[breakpointsTheme.down("md")]: {
				fontSize: "24px",
			},
		},
		body1Book: {
			fontSize: "20px",
			fontFamily: "var(--fontFamilyHeadings)",
			fontWeight: 400,
		},
		body1Medium: {
			fontSize: "20px",
			fontFamily: "var(--fontFamilyHeadings)",
			fontWeight: 500,
		},
		body1Black: {
			fontSize: "20px",
			fontFamily: "var(--fontFamilyHeadings)",
			fontWeight: 800,
		},

		body2Book: {
			fontSize: "16px",
			fontWeight: 400,
			fontFamily: "var(--fontFamilyHeadings)",
			[breakpointsTheme.down("sm")]: {
				fontSize: "14px",
			},
		},
		body2Medium: {
			fontSize: "16px",
			fontFamily: "var(--fontFamilyHeadings)",
			fontWeight: 500,
		},
		body2Black: {
			fontSize: "16px",
			fontWeight: 800,
			fontFamily: "var(--fontFamilyHeadings)",
			[breakpointsTheme.down("sm")]: {
				fontSize: "16px",
			},
		},

		bodyCopyB1Book: {
			fontSize: "16px",
			fontWeight: 500,
			fontFamily: "var(--fontFamilyHeadings)",
		},
		bodyCopyB1Medium: {
			fontSize: "16px",
			fontWeight: 500,
			fontFamily: "var(--fontFamilyHeadings)",
			[breakpointsTheme.down("sm")]: {
				fontSize: "16px",
			},
		},

		bodyCopyB1Black: {
			fontSize: "16px",
			fontWeight: 800,
			fontFamily: "var(--fontFamilyHeadings)",
		},

		bodyCopyB2Book: {
			fontSize: "14px",
			fontWeight: 500,
		},
		bodyCopyB2Black: {
			fontSize: "14px",
			fontWeight: 800,
		},

		bodyCaption: {
			fontSize: "12px",
			fontWeight: 400,
		},
		bodyCaptionR: {
			fontSize: "11px",
			fontWeight: 400,
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					body3: "p", // Map the new variant to render a 'p' tag by default
					body4: "p", // Map the new variant to render a 'p' tag by default
				},
			},
		},
	},
};
