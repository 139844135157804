import React from "react";
import {Outlet, RouteProps, useLocation, useNavigate} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {ITermsController} from "views/controllers/terms/terms.controller";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {PagePreloader} from "views/components/preloader";

export const CheckTerms: React.FC<RouteProps> = observer(() => {
	const {
		notAlllowedToAccessAFL,
		notAlllowedToAccessNRL,
		notAlllowedToAccessHR,
		notAlllowedToAccessGH,
		isLoading,
	} = useViewController<ITermsController>(Bindings.TermsController, {
		navigate: useNavigate(),
		location: useLocation(),
	});
	if (notAlllowedToAccessNRL) {
		return null;
	}
	if (notAlllowedToAccessAFL) {
		return null;
	}
	if (notAlllowedToAccessHR) {
		return null;
	}
	if (notAlllowedToAccessGH) {
		return null;
	}

	if (isLoading) {
		return <PagePreloader />;
	}

	return <Outlet />;
});
