import {ThemeOptions} from "@mui/material/styles/createTheme";
import {css} from "@mui/material/styles";

export const textFieldTheme: ThemeOptions = {
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: (props) => css`
					border-color: ${props.theme.palette.input.main};
					color: ${props.theme.palette.input.main};
					&.Mui-focused {
						color: ${props.theme.palette.input.hover};
					}
				`,
				focused: (props) => css`
					border-color: ${props.theme.palette.input.main};
				`,
				input: (props) => css`
					border-color: ${props.theme.palette.input.main};
					color: ${props.theme.palette.input.main};
				`,
				root: (props) => css`
					&:hover .MuiOutlinedInput-notchedOutline {
						border-color: ${props.theme.palette.input.main};
						color: ${props.theme.palette.input.main};
					}
					&.Mui-focused .MuiOutlinedInput-notchedOutline {
						border-color: ${props.theme.palette.input.hover};
					}
				`,
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				outlined: (props) => css`
					border-color: ${props.theme.palette.input.main};
					color: ${props.theme.palette.input.main};
					&.Mui-focused {
						color: ${props.theme.palette.input.hover};
					}
				`,
			},
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: "#fff",
				},
				outlined: {
					color: "#fff",
				},
			},
		},
	},
};
