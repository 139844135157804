import {observer} from "mobx-react";
import React from "react";
import {
	ModalGeneral,
	ModalTabContent,
	ModalWrap,
} from "views/components/modals/modal_general.component";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {IModalUsernameController} from "views/components/modals/modal_username.controller";
import {Button, FormControl, FormHelperText, InputLabel, OutlinedInput, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {RequestState} from "data/enums";
import {noop} from "lodash";
import {Exist} from "views/components/exist/exist.component";

const ModalWrapUsername = styled(ModalWrap)`
	color: var(--tabx-neutrals-off-black);
	h2 {
		color: var(--tabx-primary-dark-green);
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 32px */
		letter-spacing: 5px;
		text-transform: uppercase;
	}
	> p {
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%; /* 22.4px */
		margin: 16px 0;
	}
	.MuiButton-root {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 160%; /* 25.6px */
		text-transform: uppercase;
	}
	.MuiOutlinedInput-root,
	.MuiInputLabel-root,
	.MuiOutlinedInput-notchedOutline {
		color: var(--tabx-primary-tab-green);
		border-color: var(--tabx-primary-tab-green);
	}
	input {
		color: var(--tabx-neutrals-off-black);
	}
	.MuiFormHelperText-root {
		color: var(--tabx-neutrals-grey);
		font-size: 12px;
	}
`;

const FormHelperTextError = styled(FormHelperText)`
	color: red !important;
`;

export const ModalUsername: React.FC = observer(() => {
	const {isOpen, userName, error, requestState, handleFormSubmit} =
		useViewController<IModalUsernameController>(Bindings.ModalUsernameController);

	if (!isOpen) {
		return null;
	}
	return (
		<ModalGeneral
			isVisible={isOpen}
			heading={""}
			showCancel={false}
			onClose={noop}
			showCloseIcon={false}>
			<ModalTabContent>
				<ModalWrapUsername>
					<h2>Pick a display Name</h2>
					<p>
						Your Display Name will be used on leaderboards across the suite of TAB Pick
						& Win games.
					</p>
					<form onSubmit={handleFormSubmit} noValidate={true}>
						<Stack direction="column" gap={3}>
							<FormControl fullWidth>
								<InputLabel htmlFor="component-outlined">Display Name</InputLabel>
								<OutlinedInput
									id="component-outlined"
									defaultValue={userName}
									label="Your Display Name"
									name="username"
									inputProps={{maxLength: 40}}
									required={true}
									error={Boolean(error?.username)}
								/>
								<Exist when={!error?.username}>
									<FormHelperText>
										You must not include any personally identifiable information
									</FormHelperText>
								</Exist>
								<FormHelperTextError>{error?.username}</FormHelperTextError>
							</FormControl>
							<Stack sx={{flexDirection: {xs: "column", md: "row"}}} gap={3.75}>
								<Button
									fullWidth
									sx={{minWidth: {xs: "100%", md: 180}}}
									variant="contained"
									type="submit"
									endIcon={
										{
											[RequestState.IDLE]: <ArrowForwardIcon />,
											[RequestState.PENDING]: <MoreHorizIcon />,
											[RequestState.SUCCESS]: <CheckIcon />,
											[RequestState.ERROR]: <ErrorOutlineIcon />,
										}[requestState]
									}>
									Start Playing
								</Button>
							</Stack>
						</Stack>
					</form>
				</ModalWrapUsername>
			</ModalTabContent>
		</ModalGeneral>
	);
});
