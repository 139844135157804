export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum Locale {
	EN_US = "en-US",
	PT_BR = "pt-BR",
}

export enum Language {
	EN = "en",
	PR = "pt",
}

export enum GameName {
	AFL = "AFL",
	NRL = "NRL",
	HR = "Horse Racing",
	//GH = "Greyhound Racing",
	// NBA = "NBA Finals",
	// SOO = "State of Origin",
	// GAMES = "The Games 2024",
}

export enum MarketTypeEnum {
	EVENT_RESULT = "event_result",
	OVER_UNDER = "over_under",
	TOTAL_GOALS = "total_goals",
	HANDICAP = "handicap",
}

export enum ContestStatusEnum {
	LIVE = "live",
	COMPLETE = "complete",
	OPEN = "open",
	SCHEDULED = "scheduled",
}

export enum QuestionStatusEnum {
	LOCKED = "locked",
	COMPLETE = "complete",
	OPEN = "open",
}

export enum QuestionTypeEnum {
	MULTIPLE_CHOICE = "multiple_choice",
	TIEBREAKER = "tie_breaker",
}

export enum AnswerStatusEnum {
	CORRECT = "correct",
	IN_CORRECT = "in_correct",
	LOCKED = "locked",
	MISSED = "missed",
}

export enum ModalType {
	ERROR,
	LEAGUE_JOINED,
	LEAGUE_REMOVE_USER,
	LEAGUE_LEAVE,
	LEAGUE_REMOVE,
	GENERAL,
	MINIPLAYER,
	TERMS,
}

export enum Sport {
	AFL = "afl_football",
	NRL = "rugby_league",
	HR = "horse_racing",
	GH = "greyhounds",
	NBA = "nba",
	SOO = "soo",
}

export enum SportParam {
	AFL = "afl",
	NRL = "nrl",
	HR = "hr",
	GH = "gh",
	NBA = "nba",
	SOO = "soo",
	GAMES = "games",
}
export enum RacesStatusEnum {
	SCHEDULED = "scheduled",
	OPEN = "open",
	COMPLETE = "complete",
	LOCKED = "locked",
}

export enum PlacerStatus {
	WINNER = "winner",
	PLACER = "placer",
	LOSER = "loser",
}

export enum WeekStatus {
	PAST = "Past",
	FUTURE = "Future",
	MISSING = "Missing",
}

export enum RaceOrTips {
	RACE = "RACE",
	TIPS = "TIPS",
}
