import {makeAutoObservable, observable, runInAction} from "mobx";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaderboardItem} from "data/types/leaderboard";
import {RequestState} from "data/enums";
import {ICommonLeaderboardController} from "views/components/leaderboard/leaderboard.controller";
import {DEFAULT_LIST_SIZE} from "data/constants";
import {cloneDeep} from "lodash";
import type {IContestLeaderboardStore} from "data/stores/leaderboard/contestleaderboard.store";

/*** TODO : this may not used anymore  ***/

type Params = Parameters<NonNullable<ICommonLeaderboardController["init"]>>[0];

export type IContestLeaderboardController = ICommonLeaderboardController;

@injectable()
export class ContestLeaderboardController implements IContestLeaderboardController {
	@observable private _contestId: number = 0;
	@observable private _requestState: RequestState = RequestState.IDLE;
	private _currentPage = 1;
	private _limit: number = DEFAULT_LIST_SIZE;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestLeaderboardStore)
		public _contestleaderboardStore: IContestLeaderboardStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		// return this._requestState === RequestState.PENDING;
		return false;
	}

	get hasMore(): boolean {
		return this._contestleaderboardStore.contestleaderboard.nextPage;
	}

	get list(): ILeaderboardItem[] {
		return this._contestleaderboardStore.contestleaderboard.rankings;
	}

	get listWithCurentUser(): ILeaderboardItem[] {
		const listWithoutCurrentUser = cloneDeep(
			this._contestleaderboardStore.contestleaderboard.rankings
		);
		const currentUser = this._contestleaderboardStore.contestleaderboard.userRanking;

		const isIncludingUser = listWithoutCurrentUser.some(function (item) {
			return item.userId === currentUser?.userId;
		});

		if (!isIncludingUser && currentUser) {
			listWithoutCurrentUser.push(currentUser);
		}

		return listWithoutCurrentUser;
	}

	get userId(): number {
		return this._contestleaderboardStore.contestleaderboard.userRanking?.userId || 0;
	}

	onLoadMore = async () => {
		this._requestState = RequestState.PENDING;
		this._currentPage = this._currentPage + 1;
		try {
			await this._contestleaderboardStore.fetchContestLoadMore({
				page: this._currentPage,
				lang: this.i18n.lang,
				contestId: this._contestId,
				limit: this._limit,
			});
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	};

	async init(param: Params) {
		this._contestId = param.contestId;

		this._requestState = RequestState.PENDING;

		await this._fetchLeaderboard();
	}

	onChange(param: Params) {
		this._contestId = param.contestId;
		void this._fetchLeaderboard();
	}

	private async _fetchLeaderboard() {
		if (!this._contestId) {
			return;
		}
		this._requestState = RequestState.PENDING;

		try {
			await this._contestleaderboardStore.fetchContest({
				lang: this.i18n.lang,
				contestId: this._contestId,
				limit: this._limit,
			});
			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}
}
