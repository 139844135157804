import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modal.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {get} from "lodash";
import {action, observable, makeAutoObservable} from "mobx";
import {Language, ModalType, RequestState} from "data/enums";
import {SyntheticEvent} from "react";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IUserApiProvider} from "data/providers/api/user.api.provider";

interface IAcceptTnC extends HTMLFormElement {
	agree: HTMLInputElement;
}

export interface IModalTermsController extends ViewController {
	get userTerms(): string[];
	get isOpen(): boolean;
	get modalContent(): string;
	get modalSport(): string;
	get requestState(): RequestState;
	close: () => void;
	handleFormSubmit: (e: SyntheticEvent<IAcceptTnC>) => void;

	get error(): Record<string, string> | null;
}

@injectable()
export class ModalTermsController implements IModalTermsController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider
	) {
		makeAutoObservable(this);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "username"]: this._errorMsg,
		};
	}

	get requestState() {
		return this._requestState;
	}

	get modalContent() {
		return get(this._modalsStore.modalContent, "message", "").toLocaleLowerCase();
	}

	get modalSport() {
		return get(this._modalsStore.modalContent, "payload", "") as string;
	}

	get userName() {
		return get(this._userStore.user, "username", "");
	}

	get userTerms() {
		return get(this._userStore.user, "acceptTerms", []);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TERMS;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	handleFormSubmit = async (event: SyntheticEvent<IAcceptTnC>) => {
		this._requestState = RequestState.PENDING;
		event.preventDefault();
		const {agree} = event.currentTarget;
		if (agree) {
			await this._userApi.acceptTerms({lang: Language.EN}).catch(this.onError);
			this._requestState = RequestState.SUCCESS;
			window.location.reload();
		}
	};

	close = () => {
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
