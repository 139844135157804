import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {RefObject, useRef} from "react";
import {debounce} from "lodash";

export interface IHocSliderController extends ViewController {
	scrollToIndex: (elementIndex: number, animate?: boolean) => void;
	scrollPrev: () => void;
	scrollNext: () => void;
	onScroll: () => void;

	get scrollRef(): RefObject<HTMLDivElement> | null;

	get currentIndex(): number;

	get hasNext(): boolean;

	get hasPrev(): boolean;

	get nextIndex(): number;

	get prevIndex(): number;
}

@injectable()
export class HocSliderController implements IHocSliderController {
	@observable private _currentScrollLeft: number = 0;

	constructor() {
		makeAutoObservable(this);
	}

	private _scrollRef = useRef<HTMLDivElement | null>(null);

	get scrollRef() {
		return this._scrollRef;
	}

	public onScroll = debounce(() => {
		const scrollList = this._scrollRef?.current;
		if (scrollList) {
			this._currentScrollLeft = scrollList.scrollLeft;
		}
	});

	@observable private _currentIndex: number = 0;

	get currentIndex() {
		return this._currentIndex;
	}

	get nextIndex() {
		return this._currentIndex + 1;
	}

	get prevIndex() {
		return this._currentIndex - 1;
	}

	get hasNext() {
		return this.isRightScrollable;
	}

	get hasPrev() {
		return this.isLeftScrollable;
	}

	private get isLeftScrollable() {
		const scrollList = this._scrollRef?.current;
		if (scrollList) {
			return this._currentScrollLeft > 0;
		}
		return false;
	}

	private get isRightScrollable() {
		const scrollList = this._scrollRef?.current;
		if (scrollList) {
			return scrollList.scrollWidth >= scrollList.clientWidth + this._currentScrollLeft;
		}

		return false;
	}

	scrollToIndex = (elementIndex: number, animate: boolean = true) => {
		const scrollList = this._scrollRef?.current;
		if (scrollList) {
			const elementByIndex = scrollList.children[elementIndex] as HTMLElement;

			if (elementByIndex) {
				scrollList.scrollTo({
					left: elementByIndex.offsetLeft,
					behavior: animate ? "smooth" : "auto",
				});

				this._currentScrollLeft = elementByIndex.offsetLeft;
			}
		}
	};

	scrollPrev = () => {
		if (this.isLeftScrollable) {
			this.scrollTo();
		}
	};

	scrollNext = () => {
		if (this.isRightScrollable) {
			this.scrollTo(true);
		}
	};

	private scrollTo(isRight?: boolean) {
		const scrollList = this._scrollRef?.current;
		const firstElementWidth = scrollList?.firstElementChild?.scrollWidth;
		if (scrollList && firstElementWidth) {
			const nextScroll = isRight ? 1 : -1;
			const newScroll =
				scrollList.scrollLeft + scrollList.firstElementChild.scrollWidth * nextScroll;
			scrollList.scrollTo({
				left: newScroll,
				behavior: "smooth",
			});

			this._currentScrollLeft = newScroll;
		}
	}
}
