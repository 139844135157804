import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import aflComplete from "assets/img/afl/afl-completed-full.png";
import nrlFull from "assets/img/nrl/nrl-full.png";
import ghFull from "assets/img/gh/gh-full.png";
import aflFull from "assets/img/afl/afl-full.png";
import hrHalf from "assets/img/TAB_X_3.png";
import afl_coming_soon from "assets/img/afl/afl_coming_soon.jpg";
import afl_complete from "assets/img/afl/afl_complete.jpg";
import afl_live from "assets/img/afl/afl_live.jpg";
import afl_open from "assets/img/afl/afl_open.jpg";
import nrl_coming_soon from "assets/img/nrl/nrl_coming_soon.jpg";
import nrl_complete from "assets/img/nrl/nrl_complete.jpg";
import nrl_live from "assets/img/nrl/nrl_live.jpg";
import nrl_open from "assets/img/nrl/nrl_open.jpg";
import hr_coming_soon from "assets/img/hr/hr_coming_soon.jpg";
import hr_complete from "assets/img/hr/hr_complete.jpg";
import hr_live from "assets/img/hr/hr_live.jpg";
import hr_open from "assets/img/hr/hr_open.jpg";
import gh_coming_soon from "assets/img/gh/gh_coming_soon.jpg";
import gh_complete from "assets/img/gh/gh_complete.jpg";
import gh_live from "assets/img/gh/gh_live.jpg";
import gh_open from "assets/img/gh/gh_open.jpg";
import nba_open from "assets/img/nba/nba_open.png";
import nba_live from "assets/img/nba/nba_live.png";
import nba_coming_soon from "assets/img/nba/nba_coming_soon.png";
import nba_complete from "assets/img/nba/nba_complete.png";
import soo_open from "assets/img/soo/soo_open.png";
import soo_live from "assets/img/soo/soo_live.png";
import soo_coming_soon from "assets/img/soo/soo_coming_soon.png";
import soo_complete from "assets/img/soo/soo_complete.png";
import paris_coming_soon from "assets/img/games/games_coming_soon.png";
import paris_live from "assets/img/games/games_live.png";
import paris_open from "assets/img/games/games_open.png";
import paris_complete from "assets/img/games/games_complete.png";
import {get, reduce} from "lodash";
import {captureMessage} from "@sentry/react";
import {DISABLED_SPORTS} from "data/constants";
import {IContestList} from "data/types/contests";
import {ContestStatusEnum, GameName, SportParam} from "data/enums";

//todo simplify following
const getAFLStatusBKG = (contestStatus: ContestStatusEnum) => {
	switch (contestStatus) {
		case ContestStatusEnum.COMPLETE:
			return afl_complete;
		case ContestStatusEnum.LIVE:
			return afl_live;
		case ContestStatusEnum.OPEN:
			return afl_open;
		default:
			return afl_coming_soon;
	}
};

const getNRLStatusBKG = (contestStatus: ContestStatusEnum) => {
	switch (contestStatus) {
		case ContestStatusEnum.COMPLETE:
			return nrl_complete;
		case ContestStatusEnum.LIVE:
			return nrl_live;
		case ContestStatusEnum.OPEN:
			return nrl_open;
		default:
			return nrl_coming_soon;
	}
};

const getHRStatusBKG = (contestStatus: ContestStatusEnum) => {
	switch (contestStatus) {
		case ContestStatusEnum.COMPLETE:
			return hr_complete;
		case ContestStatusEnum.LIVE:
			return hr_live;
		case ContestStatusEnum.OPEN:
			return hr_open;
		default:
			return hr_coming_soon;
	}
};

const getGHStatusBKG = (contestStatus: ContestStatusEnum) => {
	switch (contestStatus) {
		case ContestStatusEnum.COMPLETE:
			return gh_complete;
		case ContestStatusEnum.LIVE:
			return gh_live;
		case ContestStatusEnum.OPEN:
			return gh_open;
		default:
			return gh_coming_soon;
	}
};
const getNBAStatusBKG = (contestStatus: ContestStatusEnum) => {
	switch (contestStatus) {
		case ContestStatusEnum.COMPLETE:
			return nba_complete;
		case ContestStatusEnum.LIVE:
			return nba_live;
		case ContestStatusEnum.OPEN:
			return nba_open;
		default:
			return nba_coming_soon;
	}
};
const getSOOStatusBKG = (contestStatus: ContestStatusEnum) => {
	switch (contestStatus) {
		case ContestStatusEnum.COMPLETE:
			return soo_complete;
		case ContestStatusEnum.LIVE:
			return soo_live;
		case ContestStatusEnum.OPEN:
			return soo_open;
		default:
			return soo_coming_soon;
	}
};

const getGamesBKG = (contestStatus: ContestStatusEnum) => {
	switch (contestStatus) {
		case ContestStatusEnum.COMPLETE:
			return paris_complete;
		case ContestStatusEnum.LIVE:
			return paris_live;
		case ContestStatusEnum.OPEN:
			return paris_open;
		default:
			return paris_coming_soon;
	}
};

const statusBackgroundMapping: {[key: string]: (status: ContestStatusEnum) => string} = {
	games: getGamesBKG,
	afl: getAFLStatusBKG,
	hr: getHRStatusBKG,
	nrl: getNRLStatusBKG,
	gh: getGHStatusBKG,
	nba: getNBAStatusBKG,
	soo: getSOOStatusBKG,
};

export const getCardBackgroundWithStatus = (
	game: string,
	contestStatus: ContestStatusEnum
): string => {
	for (const key in statusBackgroundMapping) {
		if (game.includes(key)) {
			return statusBackgroundMapping[key](contestStatus);
		}
	}
	return nrl_coming_soon;
};

const sportTitles: {[key: string]: string} = {
	[SportParam.AFL.toUpperCase()]: "AFL",
	[SportParam.NRL.toUpperCase()]: "NRL",
	[SportParam.HR.toUpperCase()]: GameName.HR,
	// [SportParam.GH.toUpperCase()]: GameName.GH,
	// [SportParam.NBA.toUpperCase()]: GameName.NBA,
	// [SportParam.SOO.toUpperCase()]: GameName.SOO,
	// [SportParam.GAMES.toUpperCase()]: GameName.GAMES,
};

export const getCardTitle = (game: string): string => {
	return sportTitles[game.toUpperCase()] || "No Title";
};

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export * from "data/utils/countdown";
export * from "data/utils/socialShare";

/*eslint-disable-next-line*/
export const getCardBackground = (path: string) => {
	if (path.includes("afl")) {
		return aflFull;
	} else if (path.includes("hr")) {
		return hrHalf;
	} else if (path.includes("nrl")) {
		return nrlFull;
	} else if (path.includes("gh")) {
		return ghFull;
	} else {
		return aflComplete;
	}
};

export const getSportName = (path: string) => {
	if (path.includes("afl")) {
		return "afl";
	} else if (path.includes("hr")) {
		return "hr";
	} else if (path.includes("nrl")) {
		return "nrl";
	} else if (path.includes("gh")) {
		return "gh";
	} else {
		return "";
	}
};

export const isAllTrue = (array: boolean[]) => {
	let allTrue = true;
	array.forEach((value) => {
		if (!value) {
			allTrue = false;
		}
	});
	return allTrue;
};

export const isAnyTrue = (array: boolean[]) => {
	let anyTrue = false;
	array.forEach((value) => {
		if (value) {
			anyTrue = true;
		}
	});
	return anyTrue;
};

export const trackSentryErrors = (
	exception: unknown,
	context: Record<string, unknown>,
	contextName: string = "unknown error"
) => {
	const errorName = get(exception, "message", get(exception, "text", contextName));

	captureMessage(errorName, (scope) => {
		scope.setContext(
			contextName,
			reduce(
				context,
				(acc, _value, key) => {
					if (context[key] !== undefined) {
						acc[key] = JSON.stringify(context[key]);
					}

					return acc;
				},
				{} as Record<string, unknown>
			)
		);

		scope.setTag("handler", contextName);
		scope.setTransactionName(contextName);

		return scope;
	});
};

export const isDisabledSport = (str: string) => {
	return DISABLED_SPORTS.includes(str);
};

export const filteredContest = (contestList: IContestList[], filter: string) => {
	return contestList.filter((item) => item.competition === filter?.toUpperCase());
};

export const sortByDate = (contestList: IContestList[]) => {
	return contestList.sort((objA, objB) => {
		const dateA = new Date(objA.startDate);
		const dateB = new Date(objB.startDate);

		return dateB.getTime() - dateA.getTime();
	});
};

export const nullableNummberFormat = (value: number | undefined) => {
	const format = new Intl.NumberFormat("en-US");
	if (value) {
		return format.format(value);
	} else {
		return "";
	}
};
