import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";

export interface IRegistrationPayload {
	username: string;
	email: string;
	password: string;
	lang: Language;
}

export interface IUpdateUserPayload extends Omit<IRegistrationPayload, "password" | "email"> {
	isNotificationsEnabled?: boolean;
	username: string;
	lang: Language;
}

export interface IUsername {
	username: string;
	lang: Language;
}

export interface IAcceptSport {
	lang: Language;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: (lang: Language) => Promise<AxiosResponse<TUserResponse>>;
	check_username: (params: IUsername) => Promise<AxiosResponse>;
	deactivate_account: (lang: Language) => Promise<AxiosResponse>;
	acceptTerms: (params: IAcceptSport) => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	check_username = ({lang, ...params}: IUsername) =>
		this._http.post<void>(`${lang}/user/check_username`, params);

	deactivate_account = (lang: Language) =>
		this._http.post<void>(`${lang}/user/deactivate_account`);

	register = ({lang, ...params}: IRegistrationPayload) =>
		this._http.post<TUserResponse>(`${lang}/user/register`, params);

	update = ({lang, ...params}: IUpdateUserPayload) =>
		this._http.post<TUserResponse>(`${lang}/user/update`, params);

	user = (lang: Language) => this._http.get<TUserResponse>(`${lang}/user`);

	acceptTerms = ({lang, ...params}: IAcceptSport) =>
		this._http.post<TUserResponse>(`${lang}/user/accept_terms`, params);
}
