import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";

export interface ICountry {
	name: string;
	code: string;
}

export interface ICountriesStore {
	list: ICountry[];

	fetchCountries(): Promise<void>;
}

@injectable()
export class CountriesStore implements ICountriesStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: ICountry[] = [];

	get list() {
		return this._list;
	}

	@action
	async fetchCountries() {
		const {data} = await this._jsonProvider.countries();

		runInAction(() => {
			this._list = data;
		});
	}
}
