import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";

export interface ILoginPayload {
	email: string;
	lang: Language;
}

export interface IAuthPayload {
	code: string;
	lastName: string;
	lang: Language;
}

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: (lang: Language) => Promise<AxiosResponse>;
	auhtenticate: (params: IAuthPayload) => Promise<AxiosResponse<TLoginResponse>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = ({lang, ...params}: ILoginPayload) =>
		this._http.post<TLoginResponse>(`${lang}/auth/backdoor`, params);

	logout = (lang: Language) => this._http.post(`${lang}/auth/logout`);

	auhtenticate = ({lang, ...params}: IAuthPayload) =>
		this._http.post<TLoginResponse>(`${lang}/auth/login`, params);
}
