import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {RequestState, Sport} from "data/enums";
import {useLocation, useNavigate} from "react-router-dom";
import {ICompetition} from "data/types/competitions";
import type {
	INotificationBar,
	INotificationBarStore,
} from "data/stores/notification/notification.store";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import type {IUserStore} from "data/stores/user/user.store";
import {isAllTrue, isDisabledSport} from "data/utils";
import {SecretGateController} from "views/controllers/secrete_gate/secret_gate.controller";

interface IParams {
	navigate: ReturnType<typeof useNavigate>;
	game?: string | undefined;
	location: ReturnType<typeof useLocation>;
}

interface ISportSelection {
	selected?: Sport;
	visible?: boolean;
	name?: string;
}

export interface ILandingController extends ViewController<IParams> {
	get sport(): ISportSelection;
	get notificationBar(): INotificationBar;
	get stage(): string;
	get getUpdateState(): RequestState;

	get error(): Record<string, string> | null;
	onCancel: () => void;
	handleOnselectTile: (competetion: ICompetition) => void;
	setGame: (game: string) => void;

	sportStorageNumber(sport: string): string;
}

@injectable()
export class LandingController implements ILandingController {
	private _navigate: IParams["navigate"] | null = null;
	private _location!: IParams["location"];
	@observable private _landingConnextra = RequestState.IDLE;
	@observable private _sport: ISportSelection = {};
	@observable private _requestState = RequestState.IDLE;
	@observable private _stage: string = "1";
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _game = "SOO";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.NotificationBarStore) private _notificationBarStore: INotificationBarStore
	) {
		makeAutoObservable(this);
	}

	get stage() {
		return this._stage;
	}
	get sport() {
		return this._sport;
	}

	get notificationBar() {
		return this._notificationBarStore.notificationBar;
	}

	get getUpdateState(): RequestState {
		return this._requestState;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	sportStorageNumber(sport: string): string {
		const localSport = localStorage.getItem(sport.toLocaleUpperCase());
		return localSport || "";
	}
	setSport = (newVal: ISportSelection) => {
		this._sport = newVal;
	};

	onCancel = () => {
		this.setSport({});
	};

	handleOnselectTile = (competition: ICompetition) => {
		if (isDisabledSport(competition.sport) && !SecretGateController.IS_SECRET_PASSED) {
			return null;
		}
		this._navigate?.(`/sport/${competition.name.toLowerCase()}`);
	};

	dispose(): void {
		return;
	}

	async setGame(game: string) {
		this._game = game;
		await this._fetchNotificationBar();
	}

	async init(params: IParams) {
		this._navigate = params.navigate;
		if (params.game) {
			this._game = params.game;
		}
		await this._fetchNotificationBar();

		this._location = params?.location;
		const isNotExecuted = this._landingConnextra === RequestState.IDLE;
		const isPath = this._location?.pathname.includes("/landing");

		if (isAllTrue([isNotExecuted, this.isLoggedIn, isPath])) {
			this._landingConnextra = RequestState.SUCCESS;
			createConnextraScriptTag(ConnextraType.LANDING);
		}
	}

	private async _fetchNotificationBar(): Promise<void> {
		const gameMappings: {[key: string]: string} = {
			nrl: "rugby_league",
			afl: "afl_football",
			hr: "horse_racing",
			gh: "greyhound",
			nba: "nba",
			SOO: "state_of_origin",
			games: "games",
		};

		const urlGame = gameMappings[this._game] || "state_of_origin";

		try {
			await this._notificationBarStore.fetchNotificationBar({
				game: urlGame,
			});
		} catch (e) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}
}
