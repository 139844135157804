import React, {Fragment, useState} from "react";
import {observer} from "mobx-react";
import {INotificationBar} from "data/stores/notification/notification.store";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import {ReactComponent as NotificationCloseIcon} from "assets/img/notification-close-icon.svg";

const NotificationBarWrapper = styled.div`
	background-color: var(--TAB-Masterbrand-Primary-TAB-Night-60);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 78px;

	@media (max-width: 820px) {
		padding: 12px 20px;
	}
`;

const NotificationBarHeader = styled.div`
	font-size: 18px;
	font-weight: 400;
	line-height: 160%;
	width: 100%;
	max-width: 1284px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--tabx-primary-tab-line-green);

	svg {
		width: 24px;
		height: 24px;
	}
`;

const NotificationBarMessage = styled.div`
	word-wrap: break-word;
	overflow-wrap: break-word;
	max-width: 85%;
	text-align: center;
	display: flex;
	flex: auto;
	justify-content: center;
`;

interface IProps {
	notificationBarProps: INotificationBar;
}

export const NotificationBar: React.FC<IProps> = observer((props) => {
	const [showNotificationBar, setShowNotificationBar] = useState(true);

	const {notificationBarProps} = props;

	const closeNotificationBar = () => {
		setShowNotificationBar(false);
	};

	return (
		<Fragment>
			<Exist when={notificationBarProps.isEnabled && showNotificationBar}>
				<NotificationBarWrapper>
					<NotificationBarHeader>
						<NotificationBarMessage>
							{notificationBarProps.message}
						</NotificationBarMessage>
						<NotificationCloseIcon
							style={{display: "flex"}}
							onClick={closeNotificationBar}
						/>
					</NotificationBarHeader>
				</NotificationBarWrapper>
			</Exist>
		</Fragment>
	);
});
