import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import React from "react";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IResetPasswordPayload} from "data/providers/api/password.api.provider";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PASSWORD_REQUIREMENTS} from "data/constants";

interface IResetPasswordForm extends HTMLFormElement {
	password: HTMLInputElement;
	confirmPassword: HTMLInputElement;
}

export interface IResetPasswordController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<IResetPasswordForm>) => void;
	resetPassword: (params: IResetPasswordPayload) => void;
	handleFormOnChange: () => void;

	get error(): Record<string, string> | null;

	get isSuccess(): boolean | undefined;

	get isFormDisabled(): boolean;
}

@injectable()
export class ResetPasswordController implements IResetPasswordController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _token = "";
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isSuccess() {
		return this._requestState === RequestState.SUCCESS;
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	@action handleFormOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	@action resetPassword(payload: IResetPasswordPayload) {
		this._requestState = RequestState.PENDING;

		void this._userStore.resetPassword(payload).then(this.onSuccess).catch(this.onError);
	}

	@action handleFormSubmit = (event: React.SyntheticEvent<IResetPasswordForm>) => {
		event.preventDefault();
		const {password, confirmPassword} = event.currentTarget;

		if (!password.checkValidity()) {
			return this.reportError(PASSWORD_REQUIREMENTS, "password");
		}

		if (password.value !== confirmPassword.value) {
			return this.reportError("Passwords do not match", "confirmPassword");
		}

		void this.resetPassword({
			password: password.value,
			token: this._token,
			lang: this._i18nStore.lang,
		});
	};

	dispose(): void {
		return;
	}

	init() {
		const searchParams = new URLSearchParams(window.location.search);
		this._token = searchParams.get("token") || "";
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._errorMsg = extractErrorMessage(error);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}
}
