import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {IQuestion, IAnswer} from "data/types/contests";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {QuestionStatusEnum, ContestStatusEnum} from "data/enums";
import {useLocation} from "react-router-dom";

interface IParams {
	contestId: number;
	location: ReturnType<typeof useLocation>;
}

export enum ActiveResultTab {
	MyPicks,
	BetSlip,
	// Leaderboards,
}

export interface IContestResultListController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	setActiveTab: (tab: ActiveResultTab) => void;

	get isLoggedIn(): boolean;

	get isComplete(): boolean;

	get activeTab(): ActiveResultTab;

	get hasUnlockedQuestions(): boolean;

	get questionsWithAnswers(): {
		question: IQuestion;
		answer?: IAnswer;
	}[];
}

@injectable()
export class ContestResultListController implements IContestResultListController {
	@observable private _contestId = 0;
	private _location!: IParams["location"];

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	@observable private _activeTab = ActiveResultTab.MyPicks;

	get activeTab(): ActiveResultTab {
		return this._activeTab;
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	get isComplete() {
		return this.contest?.status === ContestStatusEnum.COMPLETE;
	}

	get hasUnlockedQuestions() {
		return this.questionsWithAnswers.some((it) => {
			return it.question.status === QuestionStatusEnum.OPEN;
		});
	}

	get questionsWithAnswers() {
		if (!this.contest) {
			return [];
		}

		return this.contest.questions.map((question) => {
			const answer = this._answersStore.getByID(question.id);
			return {
				question,
				answer,
			};
		});
	}

	private get contest() {
		return this._contestStore.getByID(this._contestId);
	}

	setActiveTab = (tab: ActiveResultTab) => {
		this._activeTab = tab;
	};

	init(param: IParams) {
		this._contestId = param.contestId;

		void this._contestStore.safeFetch();
		void this._answersStore.fetch({
			contestId: param.contestId,
			lang: this.i18n.lang,
		});
	}
}
