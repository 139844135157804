import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ChangeEvent, SyntheticEvent} from "react";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage, trackSentryErrors} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IMyAccountFormElement extends HTMLFormElement {
	username: HTMLInputElement;
	email: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleLogout: () => void;

	get isFormDisabled(): boolean;

	get error(): Record<string, string> | null;

	get displayName(): string;

	get user(): IUser;

	get isUpdateDisabled(): boolean;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isFormChanged = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
		this._displayName = this._userStore.user!.username;
	}

	@observable private _displayName = "";

	get displayName() {
		return this._displayName;
	}

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {username} = event.currentTarget;

		const validateList = [
			// {field: email, error: "Please provide a valid email address", place: "email"},
			{field: username, error: "Please provide your display name", place: "username"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			username: username.value,
			lang: this._i18nStore.lang,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.logout(this._i18nStore.lang).then(this.onSuccess).catch(this.onError);
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
		trackSentryErrors(error, {}, this._errorMsg);
	};
}
