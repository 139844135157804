import {inject, injectable} from "inversify";
import type {IHttpClientService, IApiResponse} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {Language} from "data/enums";
import {IAnswer, IAnswerPayload, IOdds, IRaceInfo} from "data/types/contests";

export interface IAnswerRequestPayload {
	lang: Language;
	contestId: number;
}

export interface IAnswersPayload extends IAnswerRequestPayload {
	answers: IAnswerPayload[];
	raceQuestionId?: number;
}

export interface IAnswersResponse {
	answers: IAnswer[];
}

export interface IResultPosition {
	id?: number;
	name?: string;
	odds?: number | IOdds;
	status?: string;
	isWinner?: boolean;
	placeOdds?: number;
	placeRank?: number;
	isFavourite?: boolean;
	info?: IIResultPositionInfo;
}

export interface IIResultPositionInfo {
	status: string;
	silkURL: string;
	allowPlace: boolean;
	isScratched: boolean;
	trainerName: string;
	runnerNumber: number;
	isFavouriteWin: false;
	riderDriverName: string;
	trainerFullName: string;
	riderDriverFullName: string;
	barrierNumber: number;
}

export interface IResultResponse {
	answers: IResultResponseAnswers;
	results: IResultResponseResults;
}

export interface IResultResponseAnswers {
	correctValue?: number[];
	secondPlace?: number[];
	thirdPlace?: number[];
}

export interface IResultResponseResults {
	info?: IRaceInfo;
	winner?: IResultPosition[];
	second?: IResultPosition[];
	third?: IResultPosition[];
	fourth?: IResultPosition[];
}

export interface IAllResultResponse {
	raceAnswers: IAllResultAnswersResponse[];
	raceResultWinners: {items: IAllResultItemResponse[]};
}

export interface IAllResultAnswersResponse {
	questionId?: number;
	correctValue?: number[];
	secondPlace?: number[];
	thirdPlace?: number[];
}

export interface IAllResultItemResponse {
	questionId?: number;
	winner?: number[];
	secondPlace?: IResultItemResponse[];
	thirdPlace?: IResultItemResponse[];
}

export interface IResultItemResponse {
	info: {
		runnerNumber: number;
	};
}

export interface IRequestRequestPayload {
	lang: Language;
	questionId: number;
}

export interface IAnswersApiProvider {
	save: (params: IAnswersPayload) => Promise<AxiosResponse<IApiResponse<IAnswersResponse>>>;
	get: (params: IAnswerRequestPayload) => Promise<AxiosResponse<IApiResponse<IAnswersResponse>>>;
	getResult: (
		params: IRequestRequestPayload
	) => Promise<AxiosResponse<IApiResponse<IResultResponse>>>;

	getAllResult: (
		params: IAnswerRequestPayload
	) => Promise<AxiosResponse<IApiResponse<IAllResultResponse>>>;
}

@injectable()
export class AnswersApiProvider implements IAnswersApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	save = ({lang, contestId, ...params}: IAnswersPayload) =>
		this._http.post<IApiResponse<IAnswersResponse>>(
			`${lang}/predictor/answers/${contestId}`,
			params
		);

	get = ({lang, contestId}: IAnswerRequestPayload) =>
		this._http.get<IApiResponse<IAnswersResponse>>(`${lang}/predictor/answers/${contestId}`);

	getResult = ({lang, questionId}: IRequestRequestPayload) =>
		this._http.get<IApiResponse<IResultResponse>>(
			`${lang}/predictor/contests/race-results/${questionId}`
		);

	getAllResult = ({lang, contestId}: IAnswerRequestPayload) =>
		this._http.get<IApiResponse<IAllResultResponse>>(
			`${lang}/predictor/contests/all-race-results/${contestId}`
		);
}
