import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ModalType} from "data/enums";

export interface IModalMessage {
	title?: string;
	message: string;
	errors?: unknown;
	payload?: unknown;
	callback?: (payload?: unknown) => unknown;
}

export type TLeagueModals =
	| ModalType.ERROR
	| ModalType.LEAGUE_JOINED
	| ModalType.LEAGUE_REMOVE_USER
	| ModalType.LEAGUE_REMOVE
	| ModalType.LEAGUE_LEAVE
	| ModalType.GENERAL
	| ModalType.MINIPLAYER
	| ModalType.TERMS;

export type TModalArguments =
	| [type: Exclude<ModalType, TLeagueModals>]
	| [type: TLeagueModals, content: IModalMessage];

export interface IModalsStore {
	showModal(...args: TModalArguments): void;
	hideModal(): void;
	get modal(): ModalType | null;
	get modalContent(): IModalMessage | null;
}

@injectable()
export class ModalsStore implements IModalsStore {
	@observable _visibleModal: ModalType | null = null;
	@observable _modalContent: IModalMessage | null = null;

	get modal(): ModalType | null {
		return this._visibleModal;
	}

	get modalContent(): IModalMessage | null {
		return this._modalContent;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action hideModal(): void {
		this._visibleModal = null;
		this._modalContent = null;
	}

	@action showModal(...args: TModalArguments): void {
		const [modalType, content = null] = args;

		this._visibleModal = modalType;
		this._modalContent = content;
	}
}
