import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {RequestState, ContestStatusEnum, Sport} from "data/enums";
import {IContest, IContestList} from "data/types/contests";
import {useLocation, useNavigate} from "react-router-dom";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import type {IUserApiProvider} from "data/providers/api/user.api.provider";
import {sortByDate} from "data/utils";
import {sortBy} from "lodash";
import {DateTime} from "luxon";

interface IParams {
	navigate: ReturnType<typeof useNavigate>;
	location: ReturnType<typeof useLocation>;
}

interface ISportSelection {
	selected: Sport;
	visible?: boolean;
	name: string;
}

export interface IContestListController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	get isLoading(): boolean;

	get isLoggedIn(): boolean;

	get isThisWeek(): boolean;

	get contests(): IContestList[];

	get chosenWeekContests(): IContestList[];

	get thisWeekContests(): IContestList[];

	get lastWeekContests(): IContestList[];

	get filteredContest(): IContestList[];

	get actualContestIndex(): number;

	get getUpdateState(): RequestState;

	get isNRLLocation(): boolean;
	get isAFLLocation(): boolean;

	setGame(game: string | undefined): void;

	goToQuestions(contestId: number): void;
	getFilteredContest: (game: string) => IContestList[];
	getOpenFilteredContest: (game: string) => IContestList[];
}

@injectable()
export class ContestListController implements IContestListController {
	@observable private _contestState: RequestState = RequestState.IDLE;
	@observable private _sport: ISportSelection = {selected: Sport.AFL, name: "AFL"};
	@observable private _requestState = RequestState.IDLE;
	private _navigate: IParams["navigate"] | null = null;
	private _location!: IParams["location"];
	@observable private _filterContest: IContest[] = [];
	@observable private _game: string | undefined;
	@observable private _weekList: number[] = [];

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answerStore: IAnswersStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider
	) {
		makeAutoObservable(this);
	}

	setGame(game: string | undefined) {
		this._game = game;
	}

	get isThisWeek(): boolean {
		return this._contestStore.isThisWeek;
	}

	get isLoading() {
		return this._contestState !== RequestState.SUCCESS;
	}

	get isNRLLocation() {
		return this._location?.pathname.includes("sport/nrl");
	}

	get isAFLLocation() {
		return this._location?.pathname.includes("sport/afl");
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	get contests() {
		return this._contestStore.contestList;
	}

	get chosenWeekContests() {
		return this.isThisWeek ? this._contestStore.thisWeekContestList : this.lastWeekContests;
	}

	get thisWeekContests() {
		return this._contestStore.thisWeekContestList;
	}

	get lastWeekContests() {
		return sortBy(this._contestStore.lastWeekContestList, function (o) {
			return DateTime.fromISO(o.startDate);
		}).reverse();
	}

	get filteredContest() {
		const filtered = this._contestStore.list.filter(
			(item) => item.competition === this._game?.toUpperCase()
		);

		return sortByDate(filtered);
	}

	getFilteredContest(game: string) {
		const filtered = this._contestStore.contestList.filter(
			(item) => item.competition === game.toUpperCase()
		);
		return sortByDate(filtered);
	}

	getOpenFilteredContest(game: string) {
		const filtered = this._contestStore.contestList.filter(
			(item) => item.competition === game.toUpperCase()
		);

		return filtered.filter(
			(item) =>
				item.status === ContestStatusEnum.OPEN || item.status === ContestStatusEnum.LIVE
		);
	}

	get actualContestIndex() {
		const actualIndex = this.chosenWeekContests.findIndex(
			(it) => it.status !== ContestStatusEnum.COMPLETE
		);
		return actualIndex === -1 ? 0 : actualIndex;
	}

	get getUpdateState(): RequestState {
		return this._requestState;
	}

	goToQuestions(contestId: number) {
		if (this.isLoggedIn) {
			this._navigate?.(`/contest/${contestId}`);
		} else {
			alert("Logging..");
		}
	}

	dispose(): void {
		this._contestState = RequestState.IDLE;
		this._answerStore.clear();
	}

	async init(params: IParams): Promise<void> {
		await this._competitionsStore.fetch();
		await this._contestStore.safeFetch();
		this._contestState = RequestState.SUCCESS;
		this._navigate = params.navigate;
		this._location = params?.location;
	}
}
