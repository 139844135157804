import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import {isEqual, sortBy, orderBy, toLower, deburr, lowerCase} from "lodash";
import type {
	IStaticContentStore,
	TArticle,
	ISections,
	ISection,
} from "data/stores/static_content/static_content.store";

interface IControllerProps {
	navigate: ReturnType<typeof useNavigate>;
	pathname: string;
}

export interface IHelpController extends ViewController<IControllerProps> {
	i18n: ILocalizationStore;
	updatePathname: (pathname: string) => void;
	buildPathName: (pathname: string) => string;
	isActiveTab: (tabId: number) => boolean;
	isContactUs: (tabId: number) => boolean;

	get tabs(): ISections["sections"];

	get contents(): TArticle[];

	get currentTab(): number | null;

	get prizesIds(): number[];

	get prizeSection(): ISection | undefined;

	get prizeSections(): ISection[] | undefined;

	findSectionsById(sectionId: number): TArticle[];
}

@injectable()
export class HelpController implements IHelpController {
	private static rootPath = "/help";
	private _navigate!: IControllerProps["navigate"];
	private _pathname: string = "";

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	@observable private _currentTab: number | null = null;

	get currentTab() {
		return this._currentTab;
	}

	get getSportToUse() {
		const sport = this._pathname.split("/")[2].toLowerCase();
		let sportToUse = sport;

		if (sport === "hr") {
			sportToUse = "horse";
		}
		if (sport === "gh") {
			sportToUse = "greyhounds";
		}
		return sportToUse;
	}

	get pathIncludeSport() {
		return (
			this._pathname.includes("afl") ||
			this._pathname.includes("nrl") ||
			this._pathname.includes("hr") ||
			this._pathname.includes("gh")
		);
	}

	get tabs() {
		// if (this.pathIncludeSport) {

		// 	const returnTabs = this._staticContentStore.sections.filter(
		// 		(section) =>
		// 			section.name.toLowerCase().includes(this.getSportToUse) &&
		// 			!section.name.toLowerCase().includes("prize")
		// 	);
		// 	return sortBy(returnTabs, "position");
		// }
		return sortBy(this._staticContentStore.sections, "position");
	}

	get prizesIds() {
		const prizeSections = this._staticContentStore.sections.filter((section) =>
			section.name.toLowerCase().includes("prizes")
		);
		return prizeSections.map((section) => section.id);
	}

	get prizeSection() {
		if (this.pathIncludeSport) {
			const prizeSections = this._staticContentStore.sections.filter((section) =>
				section.name.toLowerCase().includes("prizes")
			);

			return prizeSections.find((section) =>
				section.name.toLowerCase().includes(this.getSportToUse)
			);
		}
	}

	get prizeSections() {
		return this._staticContentStore.sections.filter((section) =>
			section.name.toLowerCase().includes("prizes")
		);
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	public findSectionsById(sectionId: number) {
		const sections = this.contents?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	public updatePathname = (pathname: string) => {
		const newTab = this.tabs.find((it) => pathname.includes(this.buildPathName(it.name)))?.id;

		if (newTab) {
			this.setCurrentTab(newTab);
		}
	};

	public setCurrentTab = (value: number) => {
		this._currentTab = value;
	};

	public isActiveTab = (tab: number) => {
		return isEqual(this._currentTab, tab);
	};

	public isContactUs = (tabId: number) => {
		const sections = this.findSectionsById(tabId);

		return Boolean(
			sections.find(
				({label_names, title}) =>
					label_names.includes("contact_us") || lowerCase(title).includes("contact us")
			)
		);
	};

	public buildPathName = (name: string) => {
		return toLower(deburr(name)).split(" ").join("-");
	};

	async init({navigate, pathname}: IControllerProps) {
		await this._staticContentStore.fetchStaticContent();
		this._navigate = navigate;

		// pathname = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
		// this._pathname = pathname;

		// if (isEqual(pathname, HelpController.rootPath)) {
		// 	return navigate(this.buildPathName(this.tabs[0].name), {replace: true});
		// }

		// this.updatePathname(pathname);
	}

	dispose() {
		return;
	}
}
