import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";

export interface IWeekToggleController extends ViewController {
	get isThisWeek(): boolean;

	changeIsThisWeek: (isThisWeek: boolean) => void;
}

@injectable()
export class WeekToggleController implements IWeekToggleController {
	@observable private _isThisWeek!: boolean;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get isThisWeek(): boolean {
		return this._contestStore.isThisWeek;
	}

	changeIsThisWeek = (isThisWeek: boolean): void => {
		this._contestStore.changeIsThisWeek(isThisWeek);
	};

	init = (): void => {
		this._isThisWeek = this._contestStore.isThisWeek;
	};

	dispose(): void {
		return;
	}
}
