import styled from "@emotion/styled";
import React from "react";
import {Outlet, useParams} from "react-router-dom";
import {Footer} from "views/components/footer/footer.component";
import xgrid from "assets/img/Background_centred.jpg";
import {TabHeader} from "views/components/header/tab_header.component";

const Div = styled.div`
	background: var(--tabx-neutrals-white);
	justify-content: space-between;
	// background-image: url(${xgrid});
	background-repeat-y: no-repeat;
	background-size: cover;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-size: 90%;
	& > *:nth-of-type(2) {
		flex: 1;
	}

	@media (max-width: 640px) {
		background-size: initial;
		background-position: bottom 150px right 50%;
	}
`;

export const MainWithGridBackground = styled.div`
	position: relative;
	// min-height: 412px;
	// height: -webkit-fill-available;
	display: flex;
	flex-direction: column;
	max-width: 1284px;
	margin: 0 auto;
	padding: 0px 0px 40px 0px;
	width: 100%;
	.select-label {
		display: flex;
		justify-content: space-between;
		flex-shrink: 0;
		font-size: 22px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
		max-width: 1284px;
		width: 100%;
		margin: 40px auto 35px;
	}

	@media (max-width: 1180px) {
		.select-label {
			padding: 0px 20px;
		}
	}

	@media (max-width: 640px) {
		background-size: initial;
		padding: 0px 20px 20px 20px;
		.select-label {
			margin: 10px auto 10px;
			flex-direction: column;
		}
	}
`;

const OutletWrapper = styled.main`
	@media (max-width: 640px) {
		background-repeat: no-repeat;
		background-size: cover;
		flex: 1;
	}
`;
export const WithFooterLayout: React.FC = () => {
	const {game} = useParams();

	return (
		<Div>
			<TabHeader game={game} />
			<OutletWrapper>
				<Outlet />
			</OutletWrapper>

			<Footer />
		</Div>
	);
};

export const OffSeasonLayout: React.FC = () => {
	return (
		<Div>
			<OutletWrapper>
				<Outlet />
			</OutletWrapper>
			<Footer />
		</Div>
	);
};
