import React from "react";
import {Route, Routes} from "react-router-dom";
import {OffSeasonLayout} from "views/components/layout/layout.components";
import OffSeason from "views/pages/off_season/off_season.page";

//const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

export const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route element={<OffSeasonLayout />}>
			<Route path="*" element={<OffSeason />} />
		</Route>
	</Routes>
);

export default SecretGateRoutes;
