/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {css, ThemeOptions} from "@mui/material/styles";

export const chipTheme: ThemeOptions = {
	components: {
		MuiChip: {
			styleOverrides: {
				root: (theme) => css`
					border-radius: 16px;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 400;
					padding: 16px 8px;
				`,
				colorPrimary: (props) => css`
					background: ${props.theme.palette.primary.main};
					color: ${props.theme.palette.text.secondary};
				`,
				colorSecondary: (props) => css`
					background: ${props.theme.palette.secondary.main};
					color: ${props.theme.palette.text.primary};
				`,
				colorError: (props) => css`
					background: ${props.theme.palette.error.main};
					color: ${props.theme.palette.text.primary};
				`,
			},
		},
	},
};
