/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {css, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/Button" {
	// allows to rewrite theme options

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonPropsVariantOverrides {
		linkMenu: true;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonClasses {
		linkMenu: string;
	}
}

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					margin: 2,
					textTransform: "none",
				},
				linkMenu: (theme) => css`
					text-transform: none;
					font-weight: 800;
					color: ${theme.theme.palette.colors.first};
					font-size: 16px;
					//margin: 0;
					&.active {
						background: ${theme.theme.palette.primary.main};
						color: ${theme.theme.palette.colors.second};
					}

					@media (max-width: 480px) {
						padding: 0 5px;
					}
				`,
				outlinedPrimary: css``,
				contained: css`
					height: 56px;
					font-size: 16px;
					font-weight: 800;

					&:disabled {
						opacity: 0.3;
					}
				`,
				containedPrimary: (props) => css`
					background: #008b3a;
					color: ${props.theme.palette.colors.first};

					&:hover {
						background: #33be6d;
					}

					&:disabled {
						background: #008b3a;
						color: ${props.theme.palette.colors.first};
						opacity: 0.3;
					}
				`,
				containedSecondary: (props) => css`
					background: #008542;
					background: ${props.theme.palette.backgrounds.third};
					color: ${props.theme.palette.colors.second};

					&:hover {
						background: #ffd953;
					}

					&:disabled {
						background: #ffd953;
						color: ${props.theme.palette.colors.second};
						opacity: 0.3;
					}
				`,

				containedSizeSmall: css`
					width: auto;
					padding: 8px 16px;
					height: auto;
					line-height: 1;
				`,
			},
		},
	},
};
